import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import { FaMinus, FaPlus, FaCheckCircle } from "react-icons/fa";

const VendaPratos = () => {
  const [pratos, setPratos] = useState([]);
  const [pratosSelecionados, setPratosSelecionados] = useState([]);
  const [quantidades, setQuantidades] = useState({});
  const [loading, setLoading] = useState(true);
  const [termoPesquisa, setTermoPesquisa] = useState("");
  const [numeroMesa, setNumeroMesa] = useState("");
  const [atendimentoIniciado, setAtendimentoIniciado] = useState(false);

  useEffect(() => {
    const fetchPratos = async () => {
      try {
        const res = await axios.get(
          "https://api-foodflw-production.up.railway.app/receitas",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setPratos(res.data);
        setLoading(false);
      } catch (error) {
        toast.error("Erro ao carregar os pratos disponíveis!");
        setLoading(false);
      }
    };

    fetchPratos();
  }, []);

  const handleQuantidadeChange = (pratoId, delta) => {
    setQuantidades((prevQuantidades) => {
      const novaQuantidade = (prevQuantidades[pratoId] || 0) + delta;

      if (novaQuantidade < 0) {
        return prevQuantidades;
      }

      return {
        ...prevQuantidades,
        [pratoId]: novaQuantidade,
      };
    });
  };

  const venderPratos = async () => {
    const pratosVendidos = pratosSelecionados
      .filter((prato) => quantidades[prato.value] > 0)
      .map((prato) => ({
        prato_id: prato.value,
        quantidade: quantidades[prato.value],
        numero_mesa: numeroMesa, // Certifique-se de que está enviando o número da mesa
      }));

    if (pratosVendidos.length === 0) {
      toast.warning("Insira quantidades para os pratos vendidos.");
      return;
    }

    try {
      const res = await axios.post(
        "https://api-foodflw-production.up.railway.app/pratos-vendidos",
        { pratosVendidos },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (res.status === 200) {
        toast.success("Pratos vendidos com sucesso!");
        setQuantidades({});
        setPratosSelecionados([]); // Limpa a seleção após a venda
      } else {
        throw new Error(`Erro inesperado: ${res.statusText}`);
      }
    } catch (error) {
      console.error("Erro ao vender pratos:", error);
      toast.error(
        `Erro ao vender pratos: ${
          error.response?.data?.message || error.message
        }`
      );
    }
  };

  const handlePesquisaChange = (inputValue) => {
    setTermoPesquisa(inputValue);
  };

  const handlePratosSelecionadosChange = (selectedOptions) => {
    setPratosSelecionados(selectedOptions || []);
  };

  const iniciarAtendimento = () => {
    if (!numeroMesa.trim()) {
      toast.warning("Por favor, insira o número da mesa.");
      return;
    }
    setAtendimentoIniciado(true);
  };

  return (
    <div className="p-10">
      {!atendimentoIniciado ? (
        <div className="mb-6">
          <h2 className="text-3xl font-bold mb-6">Iniciar Atendimento</h2>
          <input
            type="text"
            placeholder="Número da Mesa"
            value={numeroMesa}
            onChange={(e) => setNumeroMesa(e.target.value)}
            className="border p-2 rounded mb-4 w-full"
          />
          <button
            onClick={iniciarAtendimento}
            className="bg-darkgreen text-white px-4 py-2 rounded"
          >
            Iniciar Atendimento
          </button>
        </div>
      ) : (
        <>
          <h2 className="text-3xl font-bold mb-6">Vendas de Pratos</h2>
          <Select
            options={pratos
              .filter((prato) =>
                prato.nome.toLowerCase().includes(termoPesquisa.toLowerCase())
              )
              .map((prato) => ({
                value: prato.id,
                label: prato.nome,
                preco: prato.valor_porcoes,
              }))}
            isMulti
            placeholder="Selecione pratos..."
            onChange={handlePratosSelecionadosChange}
            onInputChange={handlePesquisaChange}
            className="mb-6"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#4F8C8C",
              },
            })}
          />
          {loading ? (
            <p className="text-center">Carregando pratos...</p>
          ) : (
            <div>
              {pratosSelecionados.map((prato) => (
                <div
                  key={prato.value}
                  className="flex justify-between items-center bg-white shadow-md p-4 rounded-lg mb-2"
                >
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold">{prato.label}</span>
                    <span className="text-gray-500">
                      Preço por porção: R$ {prato.preco}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <button
                      onClick={() => handleQuantidadeChange(prato.value, -1)}
                      className="px-3 py-2 bg-vermelho text-white rounded-md"
                      aria-label="Diminuir quantidade"
                    >
                      <FaMinus />
                    </button>
                    <span className="mx-4">
                      {quantidades[prato.value] || 0}
                    </span>
                    <button
                      onClick={() => handleQuantidadeChange(prato.value, 1)}
                      className="px-3 py-2 bg-darkgreen text-white rounded-md"
                      aria-label="Aumentar quantidade"
                    >
                      <FaPlus />
                    </button>
                  </div>
                </div>
              ))}
              <button
                onClick={venderPratos}
                className="flex items-center bg-darkgreen text-white font-semibold text-xl px-6 py-3 rounded-md mt-4"
              >
                <FaCheckCircle className="mr-2" /> Confirmar Pedido
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default VendaPratos;

import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const AdicionarIngredienteForm = ({ fetchIngredientes }) => {
  const [nomeIngrediente, setNomeIngrediente] = useState("");
  const [peso, setPeso] = useState("");
  const [unidade, setUnidade] = useState("kg");
  const [valor, setValor] = useState("");
  const [fatorCorrecao, setFatorCorrecao] = useState("");
  const [xmlFile, setXmlFile] = useState(null);

  const adicionarIngrediente = async () => {
    try {
      const fatorCorrecaoValue = fatorCorrecao
        ? parseFloat(fatorCorrecao)
        : null;
      await axios.post(
        "https://api-foodflw-production.up.railway.app/ingredientes",
        {
          nome: nomeIngrediente,
          peso,
          unidade,
          valor,
          fator_correção: fatorCorrecaoValue,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchIngredientes();
      setNomeIngrediente("");
      setPeso("");
      setUnidade("kg");
      setValor("");
      setFatorCorrecao("");
      toast.success("Ingrediente adicionado com sucesso!");
    } catch (error) {
      toast.error("Erro ao adicionar ingrediente");
    }
  };

  const handleXmlFileChange = (event) => {
    setXmlFile(event.target.files[0]);
  };

  const uploadXmlFile = async () => {
    if (!xmlFile) {
      toast.error("Por favor, selecione um arquivo XML");
      return;
    }

    const formData = new FormData();
    formData.append("file", xmlFile);

    try {
      const response = await axios.post(
        "https://api-foodflw-production.up.railway.app/ingredientes/xml",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      fetchIngredientes();
      setXmlFile(null);
      document.getElementById("fileInput").value = "";
      toast.success("Ingredientes adicionados com sucesso!");
      console.log(response.data); // Mostra os ingredientes adicionados no console
    } catch (error) {
      console.error("Erro ao adicionar ingredientes via XML:", error);
      toast.error("Erro ao adicionar ingredientes via XML");
    }
  };

  return (
    <div className="p-10 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold text-darkgreen mb-6 text-center">
        Adicionar Ingrediente
      </h2>

      <fieldset className="mb-6 p-4 border border-darkgreen rounded-lg">
        <legend className="text-xl font-semibold text-darkgreen">
          Dados do Ingrediente
        </legend>
        <div className="mb-4">
          <label
            className="block font-semibold text-darkgreen mb-1"
            htmlFor="nomeIngrediente"
          >
            Nome do Ingrediente:
          </label>
          <input
            id="nomeIngrediente"
            type="text"
            placeholder="Nome do Ingrediente"
            value={nomeIngrediente}
            onChange={(e) => setNomeIngrediente(e.target.value)}
            className="w-full px-4 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
          />
        </div>
        <div className="mb-4">
          <label
            className="block font-semibold text-darkgreen mb-1"
            htmlFor="fatorCorrecao"
          >
            Fator de Correção:
          </label>
          <input
            id="fatorCorrecao"
            type="number"
            step="0.01"
            placeholder="Opcional, insira um fator de correção"
            value={fatorCorrecao}
            onChange={(e) => setFatorCorrecao(e.target.value)}
            className="w-full px-4 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
          />
          <p className="text-md ml-0.5 font-semibold text-darkgreen">
            Se precisar calcular o fator de correção,{" "}
            <a
              href="/fator-de-correcao"
              target="_blank"
              className="text-vermelho underline"
            >
              clique aqui
            </a>
            .
          </p>
        </div>

        <div className="mb-4">
          <label
            className="block font-semibold text-darkgreen mb-1"
            htmlFor="unidade"
          >
            Unidade de Medida:
          </label>
          <select
            id="unidade"
            value={unidade}
            onChange={(e) => setUnidade(e.target.value)}
            className="w-full px-4 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
          >
            <option value="kg">Quilogramas</option>
            <option value="unidade">Unidades</option>
            <option value="litros">Litros</option>
          </select>
        </div>

        <div className="mb-4">
          <label
            className="block font-semibold text-darkgreen mb-1"
            htmlFor="valor"
          >
            Valor do Ingrediente:
          </label>
          <input
            id="valor"
            type="number"
            placeholder="Valor do Ingrediente"
            value={valor}
            onChange={(e) => setValor(e.target.value)}
            className="w-full px-4 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
          />
        </div>
      </fieldset>

      <button
        onClick={adicionarIngrediente}
        className="bg-darkgreen text-white font-semibold px-4 py-2 rounded-md hover:bg-ultradarkgreen"
      >
        Adicionar Ingrediente
      </button>

      <div className="mt-6">
        <label
          className="block font-semibold text-darkgreen mb-1"
          htmlFor="fileInput"
        >
          Carregar Arquivo XML:
        </label>
        <input
          id="fileInput"
          type="file"
          accept=".xml"
          onChange={handleXmlFileChange}
          className="w-full px-4 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
        />
        <button
          onClick={uploadXmlFile}
          className="mt-4 bg-darkgreen text-white font-semibold px-4 py-2 rounded-md hover:bg-ultradarkgreen"
        >
          Upload XML
        </button>
      </div>
    </div>
  );
};

export default AdicionarIngredienteForm;

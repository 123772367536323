import React, { useState } from "react";
import "tailwindcss/tailwind.css";

function MarkupCalculator() {
  const [cost, setCost] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [markup, setMarkup] = useState(null);
  const [error, setError] = useState("");

  const calculateMarkup = () => {
    const costValue = parseFloat(cost);
    const sellingPriceValue = parseFloat(sellingPrice);

    if (isNaN(costValue) || isNaN(sellingPriceValue)) {
      setError(
        "Por favor, insira valores válidos para custo e preço de venda."
      );
      setMarkup(null);
      return;
    }

    if (costValue <= 0) {
      setError("O custo de produção deve ser maior que zero.");
      setMarkup(null);
      return;
    }

    setError("");
    const profit = sellingPriceValue - costValue;
    const markupPercentage = (profit / costValue) * 100;
    setMarkup(markupPercentage.toFixed(2));
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-blue-50 p-8">
      <h1 className="text-3xl font-bold text-gray-700 mb-6">
        Calculadora de Markup
      </h1>
      <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-lg">
        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-600">
            Custo de Produção (R$):
          </label>
          <input
            type="number"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            className="border border-gray-300 p-2 rounded w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-600">
            Preço de Venda (R$):
          </label>
          <input
            type="number"
            value={sellingPrice}
            onChange={(e) => setSellingPrice(e.target.value)}
            className="border border-gray-300 p-2 rounded w-full"
          />
        </div>
        {error && <div className="mb-4 text-vermelho">{error}</div>}
        <button
          onClick={calculateMarkup}
          className="w-full bg-lightgreen hover:bg-sky text-white p-2 rounded"
        >
          Calcular Markup
        </button>
        {markup !== null && (
          <div className="mt-4 text-lg text-green-600">Markup: {markup}%</div>
        )}
      </div>
    </div>
  );
}

export default MarkupCalculator;

import React from "react";

const KPIExplicacoes = () => {
  // Array de objetos com informações sobre cada KPI
  const kpiInfos = [
    {
      title: "Absenteísmo",
      description:
        "O absenteísmo mede a frequência de ausências não planejadas de um funcionário no trabalho. Níveis baixos de absenteísmo indicam boa saúde e satisfação dos funcionários, enquanto níveis altos podem sinalizar problemas.",
      metrica: [
        "Excelente: 0% - 2% de absenteísmo.",
        "Bom: 2% - 4% de absenteísmo.",
        "Mediano: 4% - 6% de absenteísmo.",
        "Ruim: 6% - 8% de absenteísmo.",
        "Péssimo: Acima de 8% de absenteísmo.",
      ],
    },
    {
      title: "Frequência",
      description:
        "A frequência mede a assiduidade do funcionário, ou seja, quantas vezes o funcionário comparece ao trabalho. Uma frequência consistente é desejável e sugere responsabilidade e comprometimento.",
      metrica: [
        "Excelente: 98% - 100% de frequência.",
        "Bom: 95% - 98% de frequência.",
        "Mediano: 90% - 95% de frequência.",
        "Ruim: 85% - 90% de frequência.",
        "Péssimo: Abaixo de 85% de frequência.",
      ],
    },
    // Adicione as métricas para os outros KPIs
    {
      title: "Headcount",
      description:
        "Headcount refere-se ao número total de funcionários em uma empresa ou departamento. Esse KPI é importante para entender a estrutura da força de trabalho.",
      metrica: [
        "Excelente: Faixa ótima de headcount para a equipe.",
        "Bom: Faixa aceitável de headcount para a equipe.",
        "Mediano: Faixa mediana de headcount para a equipe.",
        "Ruim: Faixa abaixo do esperado de headcount para a equipe.",
        "Péssimo: Faixa muito abaixo ou muito acima do headcount esperado.",
      ],
    },
    {
      title: "Avaliação de Aprendizagem",
      description:
        "A avaliação de aprendizagem mede o progresso dos funcionários em relação a treinamentos ou cursos realizados. Altos níveis de desempenho indicam boa absorção de conhecimento e aplicação nas funções.",
      metrica: [
        "Excelente: 90% - 100% de desempenho.",
        "Bom: 75% - 90% de desempenho.",
        "Mediano: 60% - 75% de desempenho.",
        "Ruim: 45% - 60% de desempenho.",
        "Péssimo: Abaixo de 45% de desempenho.",
      ],
    },
    {
      title: "Investimento de Treinamento",
      description:
        "Este KPI mede o quanto a empresa está investindo no desenvolvimento dos funcionários. Um investimento adequado em treinamento é crucial para manter uma equipe qualificada e atualizada.",
      metrica: [
        "Excelente: investimento com alta eficácia e resultados positivos.",
        "Bom: investimento com resultados moderados.",
        "Mediano: investimento com resultados medianos.",
        "Ruim: investimento com resultados abaixo do esperado.",
        "Péssimo: investimento sem resultados positivos.",
      ],
    },
    {
      title: "ROI dos Treinamentos",
      description:
        "O ROI dos treinamentos mede o retorno do investimento feito em treinamentos. Um alto ROI indica que os treinamentos estão gerando valor para a empresa em termos de produtividade e desempenho dos funcionários.",
      metrica: [
        "Excelente: ROI alto (acima de 100%).",
        "Bom: ROI moderado (50% - 100%).",
        "Mediano: ROI baixo (20% - 50%).",
        "Ruim: ROI muito baixo (0% - 20%).",
        "Péssimo: ROI negativo (abaixo de 0%).",
      ],
    },
  ];

  return (
    <div className="mt-6">
      <h3 className="text-2xl font-semibold mb-4">Explicações sobre os KPIs</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {kpiInfos.map((kpi, index) => (
          <div
            key={index}
            className="bg-white p-4 border-2 shadow-lg rounded-lg"
          >
            <h4 className="text-xl font-semibold mb-2">{kpi.title}</h4>
            <p>{kpi.description}</p>
            <br></br>
            <ul className="list-disc list-inside font-semibold">
              {kpi.metrica.map((linha, i) => (
                <li key={i}>{linha}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KPIExplicacoes;

import React, { useState } from "react";
import { AiOutlineCheckCircle, AiOutlineDown } from "react-icons/ai";
import { Link } from "react-router-dom";
import Footer from "../Footer";

// Componente de Card reutilizável
const Card = ({ title, description }) => {
  return (
    <div className="flex items-center space-x-2">
      <AiOutlineCheckCircle className="text-sky" size={20} />
      <span className="text-sky">{description}</span>
    </div>
  );
};

const HomeSection = () => {
  // Estado para o benefício selecionado
  const [selectedBenefit, setSelectedBenefit] = useState("FINANCEIRO");

  // Lista de benefícios
  const benefits = [
    {
      title: "FINANCEIRO",
      details: [
        "Gerenciamento de Despesas",
        "Ficha técnica",
        "Gerenciamento de Custos",
        "Lucro Bruto",
        "Fluxo de caixa",
        "Análises com gráficos",
      ],
    },

    {
      title: "FISCAL",
      details: [
        "Apuração de impostos",
        "Emissão de notas fiscais",
        "Gestão de obrigações fiscais",
        "Sped fiscal",
        "Controle de certidões",
      ],
    },
    {
      title: "ESTOQUE E CUSTOS",
      details: [
        "Gestão de estoque",
        "Avisos de escassez",
        "Avisos de vencimento",
        "Controle de perdas",
      ],
    },

    {
      title: "PRODUTOS",
      details: [
        "Gestão de fornecedores",
        "Solicitação de compras",
        "Controle de Compras",
        "Relatórios de compras",
      ],
    },
  ];

  // Função para renderizar o conteúdo baseado no benefício selecionado
  const renderBenefitDetails = () => {
    const benefit = benefits.find((b) => b.title === selectedBenefit);
    return benefit ? (
      <div>
        <h3 className="text-xl font-semibold mb-4 text-sky">{benefit.title}</h3>
        <ul className="space-y-2">
          {benefit.details.map((detail, index) => (
            <Card key={index} description={detail} />
          ))}
        </ul>
      </div>
    ) : null;
  };

  // Função para rolar até a próxima seção
  const scrollToNextSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      {/* Header Section */}
      <section className="bg-darkgreen text-white py-12 md:py-16 lg:py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-6xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 md:mb-8 lg:mb-10">
              Tenha um sistema de gestão escalável, adaptável ao crescimento
              contínuo da sua empresa
            </h1>
            <p className="text-lg md:text-xl lg:text-2xl mb-8 md:mb-10 lg:mb-12">
              Todos os recursos essenciais para otimizar e uniformizar a gestão
              administrativa da sua empresa
            </p>
            <Link
              to="/register"
              className="inline-block py-3 px-8 bg-laranja text-white font-semibold text-2xl rounded-full hover:bg-amarelo transition duration-300"
            >
              ENTRE EM CONTATO
            </Link>
          </div>
        </div>
        <div className="text-center mt-8">
          <button
            onClick={() => scrollToNextSection("videoSection")}
            className="text-white hover:text-gray-300 transition mt-5 duration-300"
          >
            <AiOutlineDown size={52} />
          </button>
        </div>
      </section>
      <section
        id="videoSection"
        className="bg-white text-sky py-12 md:py-16 lg:py-20"
      >
        <div className="container mx-auto px-4">
          <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center justify-center space-y-6 lg:space-y-0 lg:space-x-10">
            {/* Vídeo com largura aumentada */}
            <div className="lg:w-full mb-8 lg:mb-0">
              <div className="aspect-w-16 aspect-h-9 shadow-md rounded-md overflow-hidden">
                <iframe
                  width="100%" // Alterado para 100% da largura disponível
                  height="580"
                  src="https://www.youtube.com/embed/VIDEO_ID"
                  frameBorder="0"
                  allowFullScreen
                  title="Apresentação FoodFlow"
                ></iframe>
              </div>
            </div>

            {/* Conteúdo Textual */}
            <div className="lg:w-1/3 text-center lg:text-left">
              <h2 className="text-3xl md:text-4xl lg:text-5xl font-semibold mb-6 md:mb-8 lg:mb-10">
                Conheça o FoodFlow!
              </h2>
              <p className="text-lg md:text-xl lg:text-2xl mb-6 md:mb-8 lg:mb-10">
                Assista ao nosso vídeo e descubra como o FoodFlow pode
                transformar a gestão do seu restaurante!
              </p>
              <Link
                to="/register"
                className="inline-block py-3 px-8 bg-laranja text-white font-semibold text-xl rounded-full hover:bg-amarelo transition duration-300"
              >
                ENTRE EM CONTATO
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section
        id="benefitsSection"
        className="bg-cinza py-12 md:py-16 lg:py-20"
      >
        <div className="container mx-auto px-4">
          <div className="max-w-8xl mx-auto">
            <div className="flex flex-col lg:flex-row">
              {/* Sidebar de Navegação */}
              <div className="w-full lg:w-1/3 mb-8 lg:mb-0">
                <ul className="space-y-4">
                  {benefits.map((benefit) => (
                    <li key={benefit.title}>
                      <button
                        className={`w-full text-left py-3 px-6 rounded-full text-lg font-semibold transition ${
                          selectedBenefit === benefit.title
                            ? "bg-sky text-white"
                            : "bg-white text-sky border border-sky hover:bg-sky hover:text-white"
                        }`}
                        onClick={() => setSelectedBenefit(benefit.title)}
                      >
                        {benefit.title}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              {/* Conteúdo do Benefício */}
              <div className="w-full lg:w-2/3 pl-0 lg:pl-10">
                {renderBenefitDetails()}
                <div className="mt-6">
                  <Link
                    to="/register"
                    className="inline-block py-3 px-8 bg-laranja text-white font-semibold text-lg rounded-full hover:bg-amarelo transition duration-300"
                  >
                    ENTRE EM CONTATO
                  </Link>
                </div>
              </div>
              {/* Imagem ou gráfico ao lado do conteúdo */}
              <div className="w-full h-full  pl-0 lg:pl-10 mt-10 lg:mt-0">
                <img
                  src={require("../../images/dash.webp")}
                  alt="Imagem da página inicial do usuário, onde é mostrado diversos gráficos e uma sidebar."
                  className="rounded-md shadow-md"
                  style={{ height: "302px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default HomeSection;

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CardKPI from "../CardKPI";

const EmployeePerformance = () => {
  const { funcionario } = useParams();
  const [kpi, setKpi] = useState("");
  const [params, setParams] = useState({});
  const [resultado, setResultado] = useState(null);
  const [fetchedKpis, setFetchedKpis] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Função para lidar com a mudança de KPI
  const handleKpiChange = (e) => {
    setKpi(e.target.value);
    setParams({}); // Limpa os parâmetros ao mudar o KPI
  };

  // Função para lidar com a mudança de parâmetros
  const handleParamChange = (e) => {
    const { name, value } = e.target;
    setParams({ ...params, [name]: value });
  };

  // Função para calcular o desempenho com base nos parâmetros e KPI escolhidos
  const calcularDesempenho = () => {
    let resultadoCalculo = null;

    // Verificar os parâmetros de entrada para cada KPI
    switch (kpi) {
      case "Absenteísmo":
        if (
          params.diasFaltas != null &&
          params.diasTrabalho != null &&
          params.diasTrabalho > 0
        ) {
          resultadoCalculo = (params.diasFaltas / params.diasTrabalho) * 100;
        }
        break;
      case "Frequência":
        if (
          params.diasPresentes != null &&
          params.totalDias != null &&
          params.totalDias > 0
        ) {
          resultadoCalculo = (params.diasPresentes / params.totalDias) * 100;
        }
        break;
      case "Headcount":
        if (
          params.totalFuncionarios != null &&
          params.totalDepartamentos != null &&
          params.totalDepartamentos > 0
        ) {
          resultadoCalculo =
            params.totalFuncionarios / params.totalDepartamentos;
        }
        break;
      case "Avaliação_aprendizagem":
        if (
          params.pontuacao != null &&
          params.totalPerguntas != null &&
          params.totalPerguntas > 0
        ) {
          resultadoCalculo = (params.pontuacao / params.totalPerguntas) * 100;
        }
        break;
      case "Investimento_treinamento":
        if (
          params.custoTreinamento != null &&
          params.totalFuncionarios != null &&
          params.totalFuncionarios > 0
        ) {
          resultadoCalculo = params.custoTreinamento / params.totalFuncionarios;
        }
        break;
      case "ROI_treinamentos":
        if (
          params.retornoTreinamento != null &&
          params.custoTreinamento != null &&
          params.custoTreinamento > 0
        ) {
          resultadoCalculo =
            ((params.retornoTreinamento - params.custoTreinamento) /
              params.custoTreinamento) *
            100;
        }
        break;
      default:
        break;
    }

    if (resultadoCalculo !== null) {
      setResultado(resultadoCalculo);
      salvarDesempenho(funcionario, kpi, resultadoCalculo);
    } else {
      console.error(
        "Erro ao calcular o KPI: Parâmetros inválidos ou incompletos."
      );
    }
  };

  // Função para salvar o desempenho do funcionário na API
  const salvarDesempenho = async (nomeFuncionario, kpi, valorKpi) => {
    const apiUrl = `https://api-foodflw-production.up.railway.app/funcionarios/${nomeFuncionario}/kpi`;
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ [kpi]: valorKpi }),
      });

      if (response.ok) {
        console.log(`KPI ${kpi} do funcionário salvo com sucesso.`);
      } else {
        console.error(
          `Erro ao salvar KPI ${kpi} do funcionário:`,
          response.statusText
        );
      }
    } catch (error) {
      console.error(`Erro ao enviar a requisição para KPI ${kpi}:`, error);
    }
  };

  // Função para buscar os valores dos KPIs de um funcionário
  const fetchKpis = async () => {
    const apiUrl = `https://api-foodflw-production.up.railway.app/funcionarios/${funcionario}/kpis`;
    const token = localStorage.getItem("token");

    try {
      setIsLoading(true);
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setFetchedKpis(data);
        setError(null);
      } else {
        setError(
          `Erro ao buscar os KPIs do funcionário: ${response.statusText}`
        );
      }
    } catch (error) {
      setError(`Erro ao enviar a requisição: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4">
        Cálculo de Desempenho para {funcionario}
      </h2>

      <div className="mb-4">
        <label className="block font-semibold mb-2 text-2xl">
          Escolha o KPI:
        </label>
        <select
          value={kpi}
          onChange={handleKpiChange}
          className="block w-full p-2 border rounded text-xl"
        >
          <option value="">Selecione um KPI</option>
          <option value="Absenteísmo">Absenteísmo</option>
          <option value="Frequência">Frequência</option>
          <option value="Headcount">Headcount</option>
          <option value="Avaliação_aprendizagem">
            Avaliação de aprendizagem
          </option>
          <option value="Investimento_treinamento">
            Investimento em treinamento
          </option>
          <option value="ROI_treinamentos">ROI de treinamentos</option>
        </select>
      </div>

      {kpi && (
        <div>
          {/* Campos de input para os parâmetros do KPI */}
          {kpi === "Absenteísmo" && (
            <div>
              <label className="block font-semibold mb-2">Dias Faltas:</label>
              <input
                type="number"
                name="diasFaltas"
                value={params.diasFaltas || ""}
                onChange={handleParamChange}
                className="block w-full p-2 border rounded"
              />
              <label className="block font-semibold mb-2">
                Dias de trabalho previstos:
              </label>
              <input
                type="number"
                name="diasTrabalho"
                value={params.diasTrabalho || ""}
                onChange={handleParamChange}
                className="block w-full p-2 border rounded"
              />
            </div>
          )}

          {kpi === "Frequência" && (
            <div>
              <label className="block font-semibold mb-2">
                Dias Presentes:
              </label>
              <input
                type="number"
                name="diasPresentes"
                value={params.diasPresentes || ""}
                onChange={handleParamChange}
                className="block w-full p-2 border rounded"
              />
              <label className="block font-semibold mb-2">Total de Dias:</label>
              <input
                type="number"
                name="totalDias"
                value={params.totalDias || ""}
                onChange={handleParamChange}
                className="block w-full p-2 border rounded"
              />
            </div>
          )}

          {kpi === "Headcount" && (
            <div>
              <label className="block font-semibold mb-2">
                Total de Funcionários:
              </label>
              <input
                type="number"
                name="totalFuncionarios"
                value={params.totalFuncionarios || ""}
                onChange={handleParamChange}
                className="block w-full p-2 border rounded"
              />
              <label className="block font-semibold mb-2">
                Total de Departamentos:
              </label>
              <input
                type="number"
                name="totalDepartamentos"
                value={params.totalDepartamentos || ""}
                onChange={handleParamChange}
                className="block w-full p-2 border rounded"
              />
            </div>
          )}

          {kpi === "Avaliação_aprendizagem" && (
            <div>
              <label className="block font-semibold mb-2">Pontuação:</label>
              <input
                type="number"
                name="pontuacao"
                value={params.pontuacao || ""}
                onChange={handleParamChange}
                className="block w-full p-2 border rounded"
              />
              <label className="block font-semibold mb-2">
                Total de Perguntas:
              </label>
              <input
                type="number"
                name="totalPerguntas"
                value={params.totalPerguntas || ""}
                onChange={handleParamChange}
                className="block w-full p-2 border rounded"
              />
            </div>
          )}

          {kpi === "Investimento_treinamento" && (
            <div>
              <label className="block font-semibold mb-2">
                Custo do Treinamento:
              </label>
              <input
                type="number"
                name="custoTreinamento"
                value={params.custoTreinamento || ""}
                onChange={handleParamChange}
                className="block w-full p-2 border rounded"
              />
              <label className="block font-semibold mb-2">
                Total de Funcionários:
              </label>
              <input
                type="number"
                name="totalFuncionarios"
                value={params.totalFuncionarios || ""}
                onChange={handleParamChange}
                className="block w-full p-2 border rounded"
              />
            </div>
          )}

          {kpi === "ROI_treinamentos" && (
            <div>
              <label className="block font-semibold mb-2">
                Retorno do Treinamento:
              </label>
              <input
                type="number"
                name="retornoTreinamento"
                value={params.retornoTreinamento || ""}
                onChange={handleParamChange}
                className="block w-full p-2 border rounded"
              />
              <label className="block font-semibold mb-2">
                Custo do Treinamento:
              </label>
              <input
                type="number"
                name="custoTreinamento"
                value={params.custoTreinamento || ""}
                onChange={handleParamChange}
                className="block w-full p-2 border rounded"
              />
            </div>
          )}

          <button
            onClick={calcularDesempenho}
            className="bg-sky hover:bg-blue-600 text-xl text-white px-4 py-2  rounded mt-4"
          >
            Calcular Desempenho
          </button>
        </div>
      )}

      {/* Botão para buscar os valores dos KPIs */}
      <button
        onClick={fetchKpis}
        className="bg-sky hover:bg-blue-600 text-white px-4 py-2 text-xl rounded mt-4"
      >
        Ver métricas (KPI's) de {funcionario}
      </button>

      {isLoading ? (
        <p>Carregando...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div className="mt-4">
          <h3 className="text-lg font-semibold">KPIs de {funcionario}:</h3>
          {/* Exibe os valores dos KPIs */}
          {Object.entries(fetchedKpis).map(([kpiName, kpiValue]) => (
            <p key={kpiName}>
              {kpiName}: {`${kpiValue.toFixed(2)} %`}
            </p>
          ))}
        </div>
      )}

      {resultado !== null && (
        <div className="mt-4">
          <h3 className="text-lg font-semibold">Resultado do KPI:</h3>
          <p>{`O resultado do KPI ${kpi} é: ${resultado.toFixed(2)} %`}</p>
        </div>
      )}
      <div style={{ height: "40px" }} />
      <CardKPI />
      <div style={{ height: "120px" }} />
    </div>
  );
};

export default EmployeePerformance;

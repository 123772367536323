import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Logo from "../../images/6.webp";

const Header = ({ isAuthenticated }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token JWT não encontrado no armazenamento local");
        return;
      }

      await axios.post(
        "https://api-foodflw-production.up.railway.app/logout",
        null,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      localStorage.removeItem("token");
      window.location.href = "/";
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
    }
  };

  return (
    <header className="bg-darkgreen text-white">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <Link to="/">
          <img src={Logo} alt="Logomarca do FoodFlow" className="h-14" />
        </Link>

        <nav className="hidden md:flex space-x-6">
          {isAuthenticated ? (
            <>
              <Link
                to="/perfil"
                className="text-white text-xl font-medium hover:text-cinza"
              >
                PERFIL
              </Link>
              <Link
                to="/meu-restaurante"
                className="text-white text-xl font-medium hover:text-cinza"
              >
                MEU RESTAURANTE
              </Link>
              <button
                onClick={handleLogout}
                className="text-white text-xl font-medium hover:text-cinza"
              >
                SAIR
              </button>
            </>
          ) : (
            <>
              <Link
                to="/"
                className="text-white text-xl font-medium hover:text-cinza"
              >
                HOME
              </Link>
              <a
                href="#beneficios"
                className="text-white text-xl font-medium hover:text-cinza"
              >
                BENEFÍCIOS
              </a>
              <a
                href="#funcionalidades"
                className="text-white text-xl font-medium hover:text-cinza"
              >
                FUNCIONALIDADES
              </a>
              <Link
                to="/register"
                className="text-white text-xl font-medium hover:text-cinza"
              >
                CADASTRAR
              </Link>
              <Link
                to="/login"
                className="text-white text-xl font-medium hover:text-cinza"
              >
                LOGIN
              </Link>
            </>
          )}
        </nav>

        {!isAuthenticated && (
          <button className="hidden md:block bg-laranja text-white text-xl font-semibold py-2 px-4 rounded-full hover:bg-red-600">
            EXPERIMENTE GRÁTIS
          </button>
        )}

        <div className="md:hidden flex items-center">
          <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <svg
              className="w-9 h-9 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      {isMenuOpen && (
        <nav className="md:hidden bg-darkblue text-white">
          <ul className="flex flex-col items-center space-y-4 py-4">
            {isAuthenticated ? (
              <>
                <li>
                  <Link
                    to="/perfil"
                    className="text-white text-lg hover:text-gray-400"
                  >
                    PERFIL
                  </Link>
                </li>
                <li>
                  <Link
                    to="/meu-restaurante"
                    className="text-white text-lg hover:text-gray-400"
                  >
                    MEU RESTAURANTE
                  </Link>
                </li>
                <li>
                  <button
                    onClick={handleLogout}
                    className="text-white text-lg hover:text-gray-400"
                  >
                    SAIR
                  </button>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link
                    to="/"
                    className="text-white text-lg hover:text-gray-400"
                  >
                    HOME
                  </Link>
                </li>
                <li>
                  <a
                    href="#beneficios"
                    className="text-white text-lg hover:text-gray-400"
                  >
                    BENEFÍCIOS
                  </a>
                </li>
                <li>
                  <a
                    href="#funcionalidades"
                    className="text-white text-lg hover:text-gray-400"
                  >
                    FUNCIONALIDADES
                  </a>
                </li>
                <li>
                  <Link
                    to="/register"
                    className="text-white text-lg hover:text-gray-400"
                  >
                    CADASTRAR
                  </Link>
                </li>
                <li>
                  <Link
                    to="/login"
                    className="text-white text-lg hover:text-gray-400"
                  >
                    LOGIN
                  </Link>
                </li>
                <li>
                  <button className="bg-laranja text-lg text-white py-2 px-4 rounded-full hover:bg-red-600">
                    EXPERIMENTE GRÁTIS
                  </button>
                </li>
              </>
            )}
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;

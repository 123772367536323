import React, { useState } from "react";
import ReceitaCard from "../CardReceita";
import GerarPDFButton from "../GeradorDePdf";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";

const ListaReceitas = ({ receitas, excluirReceita, fetchReceitas }) => {
  const [termoPesquisa, setTermoPesquisa] = useState("");

  const receitasFiltradas = receitas.filter((receita) =>
    receita.nome.toLowerCase().includes(termoPesquisa.toLowerCase())
  );

  return (
    <div className="container mx-auto p-8">
      <h2 className="text-3xl font-semibold text-center mb-6">
        Receitas Cadastradas
      </h2>

      {/* Campo de pesquisa */}
      <div className="flex mb-4">
        <input
          type="text"
          placeholder="Pesquisar receitas..."
          value={termoPesquisa}
          onChange={(e) => setTermoPesquisa(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-l"
        />
        <button className="bg-sky hover:bg-blue-700 text-white p-2 rounded-r">
          <FaSearch />
        </button>
      </div>

      {receitasFiltradas.length === 0 ? (
        <div className="flex flex-col justify-center items-center mt-28 mb-44 p-10 border-2 bg-green shadow-md rounded-lg max-w-md mx-auto">
          <p className="font-semibold text-ultradarkgreen text-2xl mb-2 mr-10">
            Nenhuma receita encontrada.
          </p>
          <Link
            to="/adicionar-receita"
            className="font-semibold text-2xl text-blue hover:underline"
          >
            Clique aqui para cadastrar uma nova receita.
          </Link>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {receitasFiltradas.map((receita) => (
            <ReceitaCard
              key={receita.id}
              receita={receita}
              onExcluir={excluirReceita}
              lucro={receita.markupPorcentagem}
              onEditar={fetchReceitas}
            />
          ))}
        </div>
      )}
      <div className="mt-20 flex justify-center">
        <GerarPDFButton receitas={receitasFiltradas} />
      </div>
      <div style={{ height: "10px" }} />
    </div>
  );
};

export default ListaReceitas;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js";

// Registrar os componentes necessários do Chart.js
Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title
);

const CalculaLucroBruto = () => {
  const [dadosLucroBruto, setDadosLucroBruto] = useState({
    labels: [],
    datasets: [],
  });
  const [anoSelecionado, setAnoSelecionado] = useState(
    new Date().getFullYear()
  );
  const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  // Função para calcular o lucro bruto por meses
  const calcularLucroBrutoPorMeses = async () => {
    try {
      const token = localStorage.getItem("token");
      const lucros = [];

      // Calcular lucro bruto para cada mês do ano selecionado
      for (let mes = 1; mes <= 12; mes++) {
        const apiUrl = `https://api-foodflw-production.up.railway.app/lucro-bruto?ano=${anoSelecionado}&mes=${mes}`;
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Verifique se a resposta está ok
        if (response.status === 200) {
          // Verifique se a resposta contém lucro bruto
          if (
            response.data &&
            typeof response.data.lucro_bruto !== "undefined"
          ) {
            lucros.push(response.data.lucro_bruto);
          } else {
            console.error(
              `Dados de lucro bruto não recebidos corretamente para o mês ${mes}.`
            );
            lucros.push(0); // Adicione 0 caso não tenha dados corretos
          }
        } else {
          console.error(
            `Erro ao calcular lucro bruto para o mês ${mes}:`,
            response.statusText
          );
          lucros.push(0); // Adicione 0 em caso de erro
        }
      }

      // Atualize os dados para o gráfico
      setDadosLucroBruto({
        labels: meses,
        datasets: [
          {
            label: `Lucro Bruto em ${anoSelecionado}`,
            data: lucros,
            borderColor: "#4a90e2", // Cor da linha
            borderWidth: 2,
            pointBackgroundColor: "#1f77b4", // Cor dos pontos
            pointBorderColor: "#fff", // Cor da borda dos pontos
            pointHoverBackgroundColor: "#ff7f0e", // Cor dos pontos ao passar o mouse
            pointHoverBorderColor: "#fff", // Cor da borda dos pontos ao passar o mouse
            fill: false,
          },
        ],
      });
    } catch (error) {
      console.error("Erro ao calcular lucro bruto por meses:", error);
    }
  };

  // Atualize o cálculo quando o ano selecionado mudar
  useEffect(() => {
    calcularLucroBrutoPorMeses();
  }, [anoSelecionado]);

  // Opções de configuração do gráfico
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 14,
          },
          color: "#333",
        },
      },
      title: {
        display: true,
        text: "Faturamento da Empresa",
        font: {
          size: 18,
        },
        color: "#333",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `R$ ${context.formattedValue}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: false,
        ticks: {
          callback: function (value) {
            return `R$ ${value.toFixed(2).replace(".", ",")}`;
          },
          color: "#333",
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: "Faturamento",
          color: "#333",
          font: {
            size: 14,
          },
        },
      },
      x: {
        ticks: {
          color: "#333",
          font: {
            size: 12,
          },
        },
        title: {
          display: true,
          text: "Ano",
          color: "#333",
          font: {
            size: 14,
          },
        },
      },
    },
  };

  return (
    <div className="p-8 bg-white border-2 mt-20 shadow-md rounded-lg max-w-2xl mx-auto">
      <h1 className="text-4xl font-semibold text-center mb-8">Lucro Bruto</h1>

      {/* Seletor de anos */}
      <div className="flex justify-center mb-6">
        <div className="flex flex-col items-center mr-8">
          <label
            htmlFor="ano"
            className="text-gray-700 text-2xl font-semibold mb-3"
          >
            Ano:
          </label>
          <select
            id="ano"
            value={anoSelecionado}
            onChange={(e) => setAnoSelecionado(parseInt(e.target.value))}
            className="border border-gray-400 text-xl p-2 rounded-lg"
          >
            {[anoSelecionado - 1, anoSelecionado, anoSelecionado + 1].map(
              (ano) => (
                <option key={ano} value={ano}>
                  {ano}
                </option>
              )
            )}
          </select>
        </div>
      </div>

      {/* Gráfico de lucro bruto */}
      <div
        className="chart-container mx-auto"
        style={{ width: "100%", height: "400px" }}
      >
        <Line data={dadosLucroBruto} options={options} />
      </div>
    </div>
  );
};

export default CalculaLucroBruto;

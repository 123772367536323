import React from "react";
import jsPDF from "jspdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const GerarPDFButton = ({ receitas }) => {
  const gerarPDF = () => {
    const doc = new jsPDF();
    let y = 20; // Definir um início com espaço maior no topo
    const marginX = 15; // Definir uma margem lateral

    receitas.forEach((receita, index) => {
      if (index > 0) {
        doc.addPage();
        y = 20; // Reinicia a posição Y no topo da nova página
      }

      // Nome da receita
      doc.setFontSize(22);
      doc.setFont("helvetica", "bold");
      doc.text(marginX, y, receita.nome);
      y += 15; // Espaçamento entre nome e descrição

      // Modo de Preparo
      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      doc.text(marginX, y, "Modo de Preparo");
      y += 10; // Espaçamento entre título e conteúdo

      doc.setFontSize(14);
      doc.setFont("helvetica", "normal");
      doc.text(
        marginX + 5,
        y,
        receita.modo_preparo
          .split("\n")
          .map((line, i) => (i > 0 ? `\n${line}` : line))
      );
      y += 10 * receita.modo_preparo.split("\n").length + 10; // Ajusta a posição y com base na quantidade de linhas do modo de preparo

      // Ingredientes
      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      doc.text(marginX, y, "Ingredientes");
      y += 10;

      doc.setFontSize(14);
      doc.setFont("helvetica", "normal");
      receita.ingredientes.forEach((ingrediente) => {
        doc.text(
          marginX + 5,
          y,
          `${ingrediente.nome}: ${ingrediente.quantidade} ${ingrediente.unidade}`
        );
        y += 10; // Espaçamento entre ingredientes
      });

      y += 20; // Espaçamento entre receitas
    });

    doc.save("cardapio.pdf");
  };

  return (
    <button
      onClick={gerarPDF}
      className="bg-darkgreen hover:bg-blue-700 text-white font-semibold text-lg py-2 px-4 rounded flex items-center space-x-2"
    >
      <FontAwesomeIcon icon={faFilePdf} />
      <span>Gerar PDF do Cardápio</span>
    </button>
  );
};

export default GerarPDFButton;

import React from "react";
import styled from "styled-components";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import logo from "../../images/6.webp";

// Estilos CSS com styled-components
const FooterContainer = styled.footer`
  background: #101c3c; /* Cor de fundo */
  color: white; /* Cor do texto */
  padding: 50px 20px; /* Espaçamento interno */

  @media (max-width: 768px) {
    padding: 30px 20px; /* Espaçamento interno para telas menores */
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    align-items: center;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const Logo = styled.img`
  width: 185px; /* Tamanho do logo */
  height: auto; /* Altura automática */
  margin-bottom: 20px; /* Espaçamento inferior */
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Link = styled.a`
  color: white; /* Cor do texto */
  font-family: "Roboto", sans-serif; /* Fonte */
  font-weight: 400; /* Peso da fonte */
  font-size: 14px; /* Tamanho da fonte */
  line-height: 1.6; /* Altura da linha */
  text-decoration: none; /* Sem sublinhado */
  margin-bottom: 10px; /* Espaçamento inferior */

  &:hover {
    color: #00aaff; /* Cor do texto ao passar o mouse */
  }
`;

const SocialIcons = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Icon = styled.a`
  color: white; /* Cor dos ícones */
  font-size: 24px; /* Tamanho dos ícones */
  margin: 0 10px; /* Espaçamento entre ícones */
`;

const Text = styled.p`
  font-family: "Roboto", sans-serif; /* Fonte */
  font-weight: 400; /* Peso da fonte */
  font-size: 14px; /* Tamanho da fonte */
  line-height: 1.6; /* Altura da linha */
  margin-bottom: 10px; /* Espaçamento inferior */

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const SubText = styled.p`
  font-family: "Roboto", sans-serif; /* Fonte */
  font-weight: 400; /* Peso da fonte */
  font-size: 13px; /* Tamanho da fonte */
  text-align: center;
  margin-top: 26px;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <ContentWrapper>
        <Column>
          <LogoWrapper>
            <Logo src={logo} alt="Logomarca do FoodFlow" />
          </LogoWrapper>
          <Text></Text>
        </Column>
        <Column>
          <Text>Links</Text>
          <Links>
            <Link href="#">Início</Link>
            <Link href="#">Comece Agora</Link>
            <Link href="#">Serviços</Link>
            <Link href="#">Portfólio</Link>
            <Link href="#">Corporativo</Link>
          </Links>
        </Column>
        <Column>
          <Text>Outros</Text>
          <Links>
            <Link href="#">Termos de Serviço</Link>
            <Link href="#">Política de Privacidade</Link>
            <Link href="#">FoodFlow</Link>
            <Link href="#">Portfólio</Link>
            <Link href="#">Corporativo</Link>
          </Links>
        </Column>
        <Column>
          <Text>Outros</Text>
          <Text>Siga-nos nas Redes Sociais</Text>
          <SocialIcons>
            <Icon
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF />
            </Icon>
            <Icon
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </Icon>
            <Icon
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </Icon>
            <Icon
              href="https://www.youtube.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube />
            </Icon>
            <Icon
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </Icon>
          </SocialIcons>
        </Column>
      </ContentWrapper>
      <SubText>© 2024 FoodFlow - Todos os Direitos Reservados.</SubText>
    </FooterContainer>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Profile = () => {
  const [user, setUser] = useState({ nome: "", email: "" });
  const [editedUser, setEditedUser] = useState({ nome: "", email: "" });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          "https://api-foodflw-production.up.railway.app/perfil",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setUser(response.data);
        setEditedUser(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao obter detalhes do usuário:", error);
        setLoading(false);
      }
    };
    fetchUser();
  }, []);

  const handleChange = (e) => {
    setEditedUser({ ...editedUser, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        "https://api-foodflw-production.up.railway.app/perfil",
        editedUser,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setUser(editedUser);
      toast.success("Perfil Atualizado Com Sucesso!");
    } catch (error) {
      toast.error("Erro ao Atualizar o Perfil");
    }
  };

  if (loading) {
    return <p>Carregando...</p>;
  }

  return (
    <div className="max-w-md mx-auto mt-8 mb-72 bg-white rounded-lg shadow-lg overflow-hidden ">
      {
        <>
          {/* Seção de perfil */}
          <h2 className="text-2xl font-bold text-center bg-darkgreen text-white py-4">
            Perfil
          </h2>
          <form onSubmit={handleSubmit} className="px-6 py-4">
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="nome"
              >
                Nome:
              </label>
              <input
                type="text"
                name="nome"
                id="nome"
                value={editedUser.nome}
                onChange={handleChange}
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email:
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={editedUser.email}
                onChange={handleChange}
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="bg-darkgreen hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Salvar Alterações
              </button>
            </div>
          </form>
          <div className="px-6 py-4 bg-gray-100">
            <h3 className="text-lg font-semibold mb-2">Dados do Usuário</h3>
            <p>
              <span className="font-bold">Nome:</span> {user.nome}
            </p>
            <p>
              <span className="font-bold">Email:</span> {user.email}
            </p>
          </div>
        </>
      }
    </div>
  );
};

export default Profile;

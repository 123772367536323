import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Estoque = () => {
  const [produtos, setProdutos] = useState([]);
  const [termoPesquisa, setTermoPesquisa] = useState("");
  const [novoProduto, setNovoProduto] = useState({
    nome_produto: "",
    quantidade: "",
    preco_unitario: "",
    data_validade: "",
    fornecedor: "",
  });
  const [modoEdicaoProduto, setModoEdicaoProduto] = useState(null);
  const [alertas, setAlertas] = useState([]);
  const [quantidadeOperacao, setQuantidadeOperacao] = useState("");
  const [precosAnteriores, setPrecosAnteriores] = useState([]);
  const [modalAberta, setModalAberta] = useState(false);
  const [visualizacaoAtual, setVisualizacaoAtual] = useState("estoque"); // "estoque" ou "adicionar"

  useEffect(() => {
    fetchProdutos();
  }, []);

  const fetchProdutos = async () => {
    try {
      const res = await axios.get(
        "https://api-foodflw-production.up.railway.app/estoque",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setProdutos(res.data);
      verificarAlertas(res.data);
    } catch (error) {
      toast.error("Erro ao buscar os produtos do estoque!");
    }
  };

  const verificarAlertas = (produtos) => {
    const alertasTemp = [];

    const hoje = new Date();
    const umMesDepois = new Date();
    umMesDepois.setMonth(umMesDepois.getMonth() + 1);

    produtos.forEach((produto) => {
      const dataValidade = new Date(produto.data_validade);

      const estoqueAcabando = parseFloat(produto.quantidade) <= 1;
      const prestesAVencer = dataValidade > hoje && dataValidade < umMesDepois;

      if (estoqueAcabando && prestesAVencer) {
        const mensagem = `⚠️ Produto ${
          produto.nome_produto
        } está acabando e está prestes a vencer! Restam apenas ${
          produto.quantidade
        } unidade(s) ou kg e vencerá em ${formatarData(produto.data_validade)}`;
        alertasTemp.push(mensagem);
      } else {
        if (estoqueAcabando) {
          const mensagem = `⚠️ Produto ${produto.nome_produto} está acabando! Restam apenas ${produto.quantidade} unidade(s) ou kg!`;
          alertasTemp.push(mensagem);
        }

        if (prestesAVencer) {
          const mensagem = `⚠️ Produto ${
            produto.nome_produto
          } está prestes a vencer! Vencerá em ${formatarData(
            produto.data_validade
          )}`;
          alertasTemp.push(mensagem);
        }
      }
    });

    setAlertas(alertasTemp);
  };

  const cancelarEdicao = () => {
    setModoEdicaoProduto(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNovoProduto((prevProduto) => ({
      ...prevProduto,
      [name]: value,
    }));
  };

  const abrirModalPrecosAnteriores = async (produtoId) => {
    try {
      const res = await axios.get(
        `https://api-foodflw-production.up.railway.app/estoque/${produtoId}/ultimos-precos`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setPrecosAnteriores(res.data);
      setModalAberta(true);
    } catch (error) {
      toast.error("Erro ao buscar os últimos preços do produto!");
    }
  };

  const adicionarProduto = async () => {
    try {
      await axios.post(
        "https://api-foodflw-production.up.railway.app/estoque",
        novoProduto,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchProdutos();
      setNovoProduto({
        nome_produto: "",
        quantidade: "",
        preco_unitario: "",
        data_validade: "",
        fornecedor: "",
      });
      toast.success("Produto adicionado com sucesso!");
    } catch (error) {
      toast.error("Erro ao adicionar o produto ao estoque!");
    }
  };

  const editarProduto = async () => {
    try {
      await axios.put(
        `https://api-foodflw-production.up.railway.app/estoque/${modoEdicaoProduto.id}`,
        modoEdicaoProduto,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchProdutos();
      setModoEdicaoProduto(null);
      toast.success("Produto editado com sucesso!");
    } catch (error) {
      toast.error("Erro ao editar o produto!");
    }
  };

  const excluirProduto = async (id) => {
    try {
      await axios.delete(
        `https://api-foodflw-production.up.railway.app/estoque/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchProdutos();
      toast.success("Produto excluído com sucesso!");
    } catch (error) {
      toast.error("Erro ao excluir produto do estoque!");
    }
  };

  const iniciarEdicao = (produto) => {
    setModoEdicaoProduto(produto);
  };

  const formatarData = (data) => {
    const dataObj = new Date(data);
    const dia = String(dataObj.getDate()).padStart(2, "0");
    const mes = String(dataObj.getMonth() + 1).padStart(2, "0");
    const ano = String(dataObj.getFullYear());
    return `${dia}/${mes}/${ano}`;
  };

  const handleQuantidadeChange = (e) => {
    setQuantidadeOperacao(e.target.value);
  };

  const operacaoEstoque = async (operacao, produtoId) => {
    const quantidadeOperacaoFloat = parseFloat(quantidadeOperacao);
    if (isNaN(quantidadeOperacaoFloat) || quantidadeOperacaoFloat <= 0) {
      toast.error("Quantidade inválida");
      return;
    }

    try {
      await axios.post(
        `https://api-foodflw-production.up.railway.app/estoque/${operacao}`,
        { produto_id: produtoId, quantidade: quantidadeOperacaoFloat },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      fetchProdutos();
      setQuantidadeOperacao("");
      toast.success(
        `Operação de ${
          operacao === "entrada" ? "entrada" : "saída"
        } realizada com sucesso!`
      );
    } catch (error) {
      toast.error(
        `Erro ao realizar operação de ${
          operacao === "entrada" ? "entrada" : "saída"
        }`
      );
    }
  };

  // Condicional para escolher o que renderizar com base no estado visualizacaoAtual
  const renderizarConteudo = () => {
    if (visualizacaoAtual === "estoque") {
      return (
        <div>
          <h3 className="text-2xl font-semibold mb-4">
            Lista de Produtos no Estoque:
          </h3>
          <input
            type="text"
            placeholder="Pesquisar por produtos no estoque... 🔎"
            value={termoPesquisa}
            onChange={(e) => setTermoPesquisa(e.target.value)}
            className="block w-full px-4 py-2 mb-4 border border-gray-300 rounded-md"
          />
          <ul className="grid gap-4">
            {produtos
              .filter((produto) =>
                produto.nome_produto
                  .toLowerCase()
                  .includes(termoPesquisa.toLowerCase())
              )
              .map((produto) => (
                <li
                  key={produto.id}
                  className="bg-white shadow-md rounded-lg p-4 text-lg font-semibold flex items-center justify-between"
                >
                  {modoEdicaoProduto && modoEdicaoProduto.id === produto.id ? (
                    <div className="flex flex-col w-full max-w-xs">
                      <input
                        type="text"
                        placeholder="Nome do Produto"
                        name="nome_produto"
                        value={modoEdicaoProduto.nome_produto}
                        onChange={(e) =>
                          setModoEdicaoProduto({
                            ...modoEdicaoProduto,
                            [e.target.name]: e.target.value,
                          })
                        }
                        className="mb-2 px-4 py-2 border border-gray-300 rounded-md"
                      />
                      <input
                        type="number"
                        placeholder="Quantidade"
                        name="quantidade"
                        value={modoEdicaoProduto.quantidade}
                        onChange={(e) =>
                          setModoEdicaoProduto({
                            ...modoEdicaoProduto,
                            [e.target.name]: e.target.value,
                          })
                        }
                        className="mb-2 px-4 py-2 border border-gray-300 rounded-md"
                      />
                      <input
                        type="number"
                        step="0.01"
                        placeholder="Preço Unitário"
                        name="preco_unitario"
                        value={modoEdicaoProduto.preco_unitario}
                        onChange={(e) =>
                          setModoEdicaoProduto({
                            ...modoEdicaoProduto,
                            [e.target.name]: e.target.value,
                          })
                        }
                        className="mb-2 px-4 py-2 border border-gray-300 rounded-md"
                      />
                      <input
                        type="date"
                        placeholder="Data de Validade"
                        name="data_validade"
                        value={modoEdicaoProduto.data_validade}
                        onChange={(e) =>
                          setModoEdicaoProduto({
                            ...modoEdicaoProduto,
                            [e.target.name]: e.target.value,
                          })
                        }
                        className="mb-2 px-4 py-2 border border-gray-300 rounded-md"
                      />
                      <input
                        type="text"
                        placeholder="Fornecedor"
                        name="fornecedor"
                        value={modoEdicaoProduto.fornecedor}
                        onChange={(e) =>
                          setModoEdicaoProduto({
                            ...modoEdicaoProduto,
                            [e.target.name]: e.target.value,
                          })
                        }
                        className="mb-2 px-4 py-2 border border-gray-300 rounded-md"
                      />
                      <button
                        onClick={editarProduto}
                        className="bg-darkgreen text-white py-2 rounded-md"
                      >
                        Salvar
                      </button>
                      <button
                        onClick={cancelarEdicao}
                        className="bg-vermelho text-white py-2 mt-2 rounded-md"
                      >
                        Cancelar
                      </button>
                    </div>
                  ) : (
                    <>
                      <button
                        onClick={() => abrirModalPrecosAnteriores(produto.id)}
                        className="bg-darkgreen text-white py-2 px-4 rounded-md mr-2"
                      >
                        Ver preços anteriores
                      </button>
                      {modalAberta && (
                        <div className="fixed inset-0 flex items-center justify-center bg-sky bg-opacity-50">
                          <div className="bg-gradient-to-r from-darkgreen via-lightgreen to-limegreen p-20 rounded-md">
                            <div className="bg-white p-12 text-lg rounded-md">
                              <h2 className="text-xl font-semibold mb-4">
                                Preços Anteriores
                              </h2>
                              <ul>
                                {precosAnteriores.map((preco, index) => (
                                  <li key={index}>
                                    Preço: R$ {preco.preco} - Data de Inserção:{" "}
                                    {formatarData(preco.data_insercao)}
                                  </li>
                                ))}
                              </ul>
                              <button
                                onClick={() => setModalAberta(false)}
                                className="bg-sky hover:bg-gray-300 text-white font-semibold py-2 px-4 rounded-md mt-4"
                              >
                                Fechar
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      <span>
                        {produto.nome_produto} - R$ {produto.preco_unitario}
                      </span>
                      <span>Quantidade: {produto.quantidade}</span>
                      <span>Fornecedor: {produto.fornecedor}</span>
                      <span>
                        Data de validade: {formatarData(produto.data_validade)}
                      </span>
                      <div>
                        <button
                          onClick={() => iniciarEdicao(produto)}
                          className="bg-darkgreen text-white py-2 px-4 rounded-md mr-2"
                        >
                          Editar
                        </button>
                        <button
                          onClick={() => excluirProduto(produto.id)}
                          className="bg-vermelho text-white py-2 px-4 rounded-md"
                        >
                          Excluir
                        </button>
                        <div className="flex items-center mt-4">
                          <input
                            type="number"
                            value={quantidadeOperacao}
                            onChange={handleQuantidadeChange}
                            className="mb-2 px-4 py-2 mr-3 border border-gray-300 rounded-md"
                          />
                          <button
                            onClick={() =>
                              operacaoEstoque("entrada", produto.id)
                            }
                            className="bg-darkgreen text-white py-2 px-4 rounded-md mr-2"
                          >
                            Entrou
                          </button>
                          <button
                            onClick={() => operacaoEstoque("saida", produto.id)}
                            className="bg-vermelho text-white py-2 px-4 rounded-md"
                          >
                            Saiu
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </li>
              ))}
          </ul>
        </div>
      );
    } else if (visualizacaoAtual === "adicionar") {
      return (
        <div className="mt-4">
          <h3 className="text-2xl font-semibold mb-4">
            Formulário para adicionar produto ao estoque:
          </h3>
          <div className="flex flex-col w-full max-w-xs">
            <h2 className="text-xl font-semibold mb-2">Nome do produto:</h2>
            <input
              type="text"
              placeholder="Nome do produto"
              name="nome_produto"
              value={novoProduto.nome_produto}
              onChange={handleInputChange}
              className="mb-2 px-4 py-2 border border-gray-300 rounded-md"
            />
            <h2 className="text-xl font-semibold mb-2">
              Quantidade adquirida do produto:
            </h2>
            <input
              type="number"
              placeholder="Quantidade"
              name="quantidade"
              value={novoProduto.quantidade}
              onChange={handleInputChange}
              className="mb-2 px-4 py-2 border border-gray-300 rounded-md"
            />
            <h2 className="text-xl font-semibold mb-2">
              Preço unitário do produto:
            </h2>
            <input
              type="number"
              step="0.01"
              placeholder="Preço unitário"
              name="preco_unitario"
              value={novoProduto.preco_unitario}
              onChange={handleInputChange}
              className="mb-2 px-4 py-2 border border-gray-300 rounded-md"
            />
            <h2 className="text-xl font-semibold mb-2">
              Data de validade do produto:
            </h2>
            <input
              type="date"
              placeholder="Data de validade"
              name="data_validade"
              value={novoProduto.data_validade}
              onChange={(e) => {
                setNovoProduto((prevProduto) => ({
                  ...prevProduto,
                  data_validade: e.target.value,
                }));
              }}
              className="mb-2 px-4 py-2 border border-gray-300 rounded-md"
            />
            <h2 className="text-xl font-semibold mb-2">Fornecedor:</h2>
            <input
              type="text"
              placeholder="Fornecedor"
              name="fornecedor"
              value={novoProduto.fornecedor}
              onChange={handleInputChange}
              className="mb-2 px-4 py-2 border border-gray-300 rounded-md"
            />
            <button
              onClick={adicionarProduto}
              className="bg-darkgreen text-white py-2 rounded-md"
            >
              Adicionar produto
            </button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="p-10">
      <h2 className="text-2xl font-semibold mb-4">Meu Estoque</h2>

      <div>
        {alertas.map((alerta, index) => (
          <div key={index} className="bg-green text-darkgreen p-2 mb-2 rounded">
            {alerta}
          </div>
        ))}

        <div className="flex gap-4 mb-4">
          <button
            onClick={() => setVisualizacaoAtual("estoque")}
            className="bg-sky text-white font-semibold py-2 px-4 rounded-md"
          >
            Visualizar Estoque
          </button>
          <button
            onClick={() => setVisualizacaoAtual("adicionar")}
            className="bg-sky text-white font-semibold py-2 px-4 rounded-md"
          >
            Adicionar Produto
          </button>
        </div>

        {renderizarConteudo()}
      </div>
      <div style={{ height: "350px" }} />
    </div>
  );
};

export default Estoque;

import React, { useEffect, useState } from "react";
import axios from "axios";

const DemittedEmployees = () => {
  // Estado para armazenar os funcionários demitidos
  const [demittedEmployees, setDemittedEmployees] = useState([]);

  // Função para buscar os dados dos funcionários demitidos
  const fetchDemittedEmployees = async () => {
    try {
      const response = await axios.get(
        "https://api-foodflw-production.up.railway.app/funcionarios/demitidos",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Certifique-se de obter o token corretamente
          },
        }
      );
      setDemittedEmployees(response.data);
    } catch (error) {
      console.error("Erro ao buscar funcionários demitidos:", error);
    }
  };

  // Função para deletar um funcionário demitido
  const deleteDemittedEmployee = async (id) => {
    try {
      // Requisição para deletar o funcionário demitido
      await axios.delete(
        `https://api-foodflw-production.up.railway.app/funcionarios/demitidos/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Certifique-se de obter o token corretamente
          },
        }
      );

      // Atualize a lista de funcionários demitidos removendo o funcionário excluído
      setDemittedEmployees((prevEmployees) =>
        prevEmployees.filter((employee) => employee.id !== id)
      );

      console.log("Funcionário demitido excluído com sucesso.");
    } catch (error) {
      console.error("Erro ao excluir funcionário demitido:", error);
    }
  };

  // Carregar os dados dos funcionários demitidos ao montar o componente
  useEffect(() => {
    fetchDemittedEmployees();
  }, []);

  // Renderizar os funcionários demitidos em cards
  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Funcionários Demitidos</h2>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        {demittedEmployees.map((employee) => (
          <div key={employee.id} className="bg-white p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold">{employee.nome}</h3>
            <p>
              <strong>Data de demissão:</strong>{" "}
              {new Date(employee.data_demissao).toLocaleDateString("pt-BR")}
            </p>
            <p>
              <strong>Motivo:</strong> {employee.motivo}
            </p>
            {/* Adicione outras informações conforme necessário */}

            {/* Botão para deletar o funcionário demitido */}
            <button
              className="mt-2 bg-vermelho text-white px-4 py-2 rounded hover:bg-red-600"
              onClick={() => deleteDemittedEmployee(employee.id)}
            >
              Excluir
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DemittedEmployees;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const Pedidos = () => {
  const [pedidos, setPedidos] = useState([]);

  const fetchPedidos = async () => {
    try {
      const response = await axios.get(
        "https://api-foodflw-production.up.railway.app/pedidos",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("Pedidos recebidos:", response.data); // Verifique se os dados estão corretos
      setPedidos(response.data);
    } catch (error) {
      console.error("Erro ao buscar pedidos:", error);
      toast.error("Erro ao buscar pedidos.");
    }
  };

  const atualizarStatusPedido = async (id, novoStatus) => {
    try {
      await axios.put(
        `https://api-foodflw-production.up.railway.app/pedidos/${id}`,
        { status: novoStatus },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchPedidos();
      toast.success("Status do pedido atualizado com sucesso!");
    } catch (error) {
      console.error("Erro ao atualizar status do pedido:", error);
      toast.error("Erro ao atualizar status do pedido.");
    }
  };

  useEffect(() => {
    fetchPedidos();
  }, []);

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-3xl font-semibold mb-6 text-center">Pedidos</h2>

      <div>
        {pedidos.length > 0 ? (
          pedidos.map((pedido) => (
            <div
              key={pedido.id}
              className="p-4 bg-white shadow-md mb-4 rounded"
            >
              <div>
                <span className="font-semibold text-lg">
                  Pedido #{pedido.id}
                </span>
                <p>Status: {pedido.status}</p>
                <p>
                  <strong>Nome do Cliente:</strong> {pedido.nome_cliente}
                </p>
                <p>
                  <strong>Telefone:</strong> {pedido.telefone_cliente}
                </p>
                <p>
                  <strong>Endereço:</strong> {pedido.endereco_cliente}
                </p>
                <p>
                  <strong>Itens do Pedido:</strong>
                </p>
                <ul>
                  {pedido.itens && pedido.itens.length > 0 ? (
                    pedido.itens.map((item, index) => (
                      <li key={index} className="mb-2">
                        {item.item_nome} - {item.quantidade}x
                        {item.observacoes && (
                          <p className="text-sm text-cinzaescuro">
                            Observação: {item.observacoes}
                          </p>
                        )}
                      </li>
                    ))
                  ) : (
                    <p>Nenhum item neste pedido.</p>
                  )}
                </ul>
                {pedido.status === "Entregue" ? (
                  <div className="flex items-center mt-2 text-lightgreen">
                    <span className="mr-2">✔️</span>
                    <span>Pedido finalizado</span>
                  </div>
                ) : (
                  <button
                    onClick={() => atualizarStatusPedido(pedido.id, "Entregue")}
                    className="mt-2 bg-darkgreen text-white px-4 py-2 rounded hover:bg-blue-700"
                  >
                    Marcar como Entregue
                  </button>
                )}
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-cinzaescuro">Nenhum pedido feito.</p>
        )}
      </div>
    </div>
  );
};

export default Pedidos;

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header";
import HomeSection from "./components/Home";
import Login from "./components/Login";
import Register from "./components/Cadastro";
import Perfil from "./components/Perfil";
import Restaurante from "./components/Restaurantes";
import CalculadoraFatorCorrecao from "./components/CalculadoraFC";
import EmployeeForm from "./components/Funcionarios";
import EmployeeList from "./components/MostrarFuncionarios";
import EmployeePerformance from "./components/CalculoDeKPI";
import AdicionarIngredienteForm from "./components/AdicionarIngrediente";
import ListaIngredientes from "./components/MostrarIngredientes";
import AdicionarReceitaForm from "./components/AdicionarReceita";
import ListaReceitas from "./components/MostrarReceitas";
import Estoque from "./components/Estoque";
import VendaPratos from "./components/VendaPratos";
import DespesasFixas from "./components/DespesasFixa";
import MarkupCalculator from "./components/CalculadoraMarkup";
import RedefinirSenhaSolicitacao from "./components/SolicitarSenhaNova";
import RedefinirSenha from "./components/RedefinirSenha";
import GestaoEmpresarial from "./components/GestaoEmpresarial";
import DemittedEmployees from "./components/FuncionariosDemitidos";
import FoodSafetyIndicators from "./components/SegurancaAlimentar";
import CalculaLucroBruto from "./components/CalcularLucroBruto";
import PratosMaisVendidos from "./components/PratosMaisVendidos";
import TicketMedio from "./components/TicketMedio";
import CriarFormulario from "./components/SatisfaçãoCliente";
import Cardapios from "./components/Cardapios";
import ItensCardapio from "./components/ItensCardapio";
import Pedidos from "./components/Pedidos";
import ItensPedidos from "./components/ItensPedidos";
import CardapioPublico from "./components/CardapioPublico";
import FormularioPublico from "./components/FormularioPublico";
import ImportClientes from "./components/CadastroClientes";
import Comanda from "./components/Comanda";
import { toast } from "react-toastify";
import axios from "axios";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [ingredientes, setIngredientes] = useState([]);
  const [termoPesquisa, setTermoPesquisa] = useState("");
  const [editandoPreco, setEditandoPreco] = useState(null);
  const [novoPreco, setNovoPreco] = useState("");
  const [ingredientesSelecionados, setIngredientesSelecionados] = useState([]);
  const [receitas, setReceitas] = useState([]);
  const [mostrarReceitas, setMostrarReceitas] = useState(false);

  const handleEditarPreco = (id, precoAtual) => {
    setEditandoPreco(id);
    setNovoPreco(precoAtual);
  };

  const fetchReceitas = async () => {
    try {
      const res = await axios.get(
        "https://api-foodflw-production.up.railway.app/receitas",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setReceitas(res.data);
    } catch (error) {
      console.error("Erro ao obter receitas:", error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchIngredientes();
      fetchReceitas();
    }
  }, [isAuthenticated]);

  const handleSalvarPreco = async () => {
    if (!isNaN(parseFloat(novoPreco))) {
      try {
        await axios.put(
          `https://api-foodflw-production.up.railway.app/ingredientes/${editandoPreco}`,
          {
            valor: parseFloat(novoPreco),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const novosIngredientes = ingredientes.map((ingrediente) => {
          if (ingrediente.id === editandoPreco) {
            return { ...ingrediente, valor: parseFloat(novoPreco) };
          }
          return ingrediente;
        });

        setIngredientes(novosIngredientes);
        setEditandoPreco(null);
        setNovoPreco("");
        toast.success("Preço do ingrediente salvo com sucesso!");
      } catch (error) {
        console.error("Erro ao salvar o preço do ingrediente:", error);
      }
    } else {
      alert("Por favor, insira um preço válido.");
    }
  };

  const excluirReceita = async (id) => {
    try {
      await axios.delete(
        `https://api-foodflw-production.up.railway.app/receitas/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchReceitas(); // Atualiza a lista de receitas após a exclusão
    } catch (error) {
      console.error("Erro ao excluir receita:", error);
    }
  };

  const fetchIngredientes = async () => {
    try {
      const res = await axios.get(
        "https://api-foodflw-production.up.railway.app/ingredientes",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // Utilize res.data para atualizar o estado ingredientes
      setIngredientes(res.data);
    } catch (error) {
      console.error("Erro ao obter ingredientes:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <div>
        <Header isAuthenticated={isAuthenticated} onLogout={handleLogout} />
        <Routes>
          <Route
            path="/login"
            element={<Login setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route path="/" element={<HomeSection />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/perfil"
            element={isAuthenticated ? <Perfil /> : <Navigate to="/login" />}
          />
          <Route
            path="/meu-restaurante"
            element={
              isAuthenticated ? <Restaurante /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/fator-de-correcao"
            element={<CalculadoraFatorCorrecao />}
          />
          <Route path="/calculadora-markup" element={<MarkupCalculator />} />
          <Route
            path="/funcionarios"
            element={
              isAuthenticated ? <EmployeeForm /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/gestao-empresarial"
            element={
              isAuthenticated ? <GestaoEmpresarial /> : <Navigate to="/login" />
            }
          />

          <Route
            path="/clientes"
            element={
              isAuthenticated ? <ImportClientes /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/ticket-medio"
            element={
              isAuthenticated ? <TicketMedio /> : <Navigate to="/login" />
            }
          />

          <Route
            path="/meus-funcionarios"
            element={
              isAuthenticated ? <EmployeeList /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/comandas"
            element={isAuthenticated ? <Comanda /> : <Navigate to="/login" />}
          />
          <Route
            path="/funcionarios/desempenho/:funcionario"
            element={
              isAuthenticated ? (
                <EmployeePerformance />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/lucro-bruto"
            element={
              isAuthenticated ? <CalculaLucroBruto /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/formularios/publico/:linkUnico"
            element={<FormularioPublico />}
          />

          <Route
            path="/satisfacao-cliente"
            element={
              isAuthenticated ? <CriarFormulario /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/crescimento"
            element={
              isAuthenticated ? (
                <PratosMaisVendidos />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/seguranca-alimentar"
            element={
              isAuthenticated ? (
                <FoodSafetyIndicators />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/adicionar-ingrediente"
            element={
              isAuthenticated ? (
                <AdicionarIngredienteForm
                  fetchIngredientes={fetchIngredientes}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/meus-ingredientes"
            element={
              isAuthenticated ? (
                <ListaIngredientes
                  ingredientes={ingredientes}
                  termoPesquisa={termoPesquisa}
                  setTermoPesquisa={setTermoPesquisa}
                  handleEditarPreco={handleEditarPreco}
                  editandoPreco={editandoPreco}
                  novoPreco={novoPreco}
                  setNovoPreco={setNovoPreco}
                  handleSalvarPreco={handleSalvarPreco}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/adicionar-receita"
            element={
              isAuthenticated ? (
                <AdicionarReceitaForm
                  fetchReceitas={fetchReceitas} // Passando a função corretamente
                  ingredientes={ingredientes}
                  ingredientesSelecionados={ingredientesSelecionados}
                  setIngredientesSelecionados={setIngredientesSelecionados}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/minhas-receitas"
            element={
              isAuthenticated ? (
                <ListaReceitas
                  receitas={receitas}
                  mostrarReceitas={mostrarReceitas}
                  setMostrarReceitas={setMostrarReceitas}
                  excluirReceita={excluirReceita}
                  fetchReceitas={fetchReceitas} // Certifique-se de passar fetchReceitas aqui
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/estoque"
            element={isAuthenticated ? <Estoque /> : <Navigate to="/login" />}
          />
          <Route
            path="/vendas"
            element={
              isAuthenticated ? <VendaPratos /> : <Navigate to="/login" />
            }
          />

          <Route
            path="/cardapios"
            element={isAuthenticated ? <Cardapios /> : <Navigate to="/login" />}
          />
          <Route
            path="/cardapios/:cardapioId/itens"
            element={
              isAuthenticated ? <ItensCardapio /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/pedidos"
            element={isAuthenticated ? <Pedidos /> : <Navigate to="/login" />}
          />
          <Route path="/cardapio/:linkUnico" element={<CardapioPublico />} />

          <Route
            path="/pedidos/:pedidoId/itens"
            element={
              isAuthenticated ? <ItensPedidos /> : <Navigate to="/login" />
            }
          />

          <Route
            path="/funcionarios-demitidos"
            element={
              isAuthenticated ? <DemittedEmployees /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/minhas-despesas"
            element={
              isAuthenticated ? <DespesasFixas /> : <Navigate to="/login" />
            }
          />
          {/* Rota para solicitação de redefinição de senha */}
          <Route
            path="/redefinir-senha"
            element={<RedefinirSenhaSolicitacao />}
          />

          {/* Rota para redefinição de senha com o token */}
          <Route path="/redefinir-senha/:token" element={<RedefinirSenha />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

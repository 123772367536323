import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const CriarFormulario = () => {
  const [nomeFormulario, setNomeFormulario] = useState("");
  const [perguntas, setPerguntas] = useState([]);
  const [linkUnico, setLinkUnico] = useState("");
  const [formularios, setFormularios] = useState([]);
  const [formularioDetalhes, setFormularioDetalhes] = useState({});

  // Função para adicionar perguntas
  const adicionarPergunta = () => {
    setPerguntas([...perguntas, { pergunta: "", tipoResposta: "1 a 5" }]);
  };

  // Função para atualizar perguntas
  const atualizarPergunta = (index, campo, valor) => {
    const perguntasAtualizadas = [...perguntas];
    perguntasAtualizadas[index][campo] = valor;
    setPerguntas(perguntasAtualizadas);
  };

  // Função para remover perguntas
  const removerPergunta = (index) => {
    const perguntasAtualizadas = perguntas.filter((_, i) => i !== index);
    setPerguntas(perguntasAtualizadas);
  };

  // Função para submeter um novo formulário
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://api-foodflw-production.up.railway.app/formularios",
        {
          nome: nomeFormulario,
          perguntas,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 201) {
        toast.success("Formulário criado com sucesso");
        const { link_unico } = response.data;
        setLinkUnico(link_unico);

        // Armazenar o link único no localStorage
        localStorage.setItem("linkUnico", link_unico);
      }
    } catch (error) {
      console.error("Erro ao criar formulário:", error);
    }
  };

  // Função para buscar formulários
  const fetchFormularios = async () => {
    try {
      const response = await axios.get(
        "https://api-foodflw-production.up.railway.app/formularios",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setFormularios(response.data);
        // Buscar perguntas e respostas para cada formulário
        response.data.forEach((formulario) => {
          fetchFormularioDetalhes(formulario.id);
        });
      } else {
        console.error(`Erro ao obter formulários: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Erro ao buscar formulários:", error);
    }
  };

  // Função para buscar detalhes de formulários
  const fetchFormularioDetalhes = async (formularioId) => {
    try {
      const response = await axios.get(
        `https://api-foodflw-production.up.railway.app/formularios/${formularioId}/perguntas-respostas`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setFormularioDetalhes((prev) => ({
          ...prev,
          [formularioId]: response.data,
        }));
      }
    } catch (error) {
      console.error(
        `Erro ao buscar perguntas e respostas para formulário ${formularioId}:`,
        error
      );
    }
  };

  // Calcular a média aritmética das respostas
  const calcularMediaAritmetica = (respostas) => {
    if (respostas.length === 0) return "Sem respostas";

    const soma = respostas.reduce((total, resposta) => total + resposta, 0);
    const media = soma / respostas.length;

    // Retornar a média com duas casas decimais
    return media.toFixed(2);
  };

  // Função para classificar a média aritmética
  const classificarMedia = (media) => {
    if (media >= 0 && media <= 1) {
      return "Ruim";
    } else if (media > 1 && media <= 3) {
      return "Mediano";
    } else if (media > 3 && media <= 4) {
      return "Bom";
    } else if (media > 4 && media <= 5) {
      return "Excelente";
    } else {
      return "Métrica desconhecida";
    }
  };

  useEffect(() => {
    fetchFormularios();

    // Recuperar o link único do localStorage quando o componente for montado
    const storedLinkUnico = localStorage.getItem("linkUnico");
    if (storedLinkUnico) {
      setLinkUnico(storedLinkUnico);
    }
  }, []);
  // Função para excluir um formulário criado
  const excluirFormulario = async (formularioId) => {
    try {
      // Faz uma requisição DELETE para a rota de exclusão de formulários
      const response = await axios.delete(
        `https://api-foodflw-production.up.railway.app/formularios/${formularioId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Se a requisição for bem-sucedida, remova o formulário excluído da lista de formulários
      if (response.status === 200) {
        // Filtre a lista de formulários removendo o formulário excluído
        setFormularios((prevFormularios) =>
          prevFormularios.filter((formulario) => formulario.id !== formularioId)
        );
        toast.success("Formulário excluído com sucesso.");

        // Verifique se o formulário excluído tinha o link único atualmente armazenado
        const formularioExcluido = formularios.find(
          (formulario) => formulario.id === formularioId
        );

        if (formularioExcluido && formularioExcluido.link_unico === linkUnico) {
          // Se forem iguais, redefina o link único e remova do localStorage
          setLinkUnico("");
          localStorage.removeItem("linkUnico");
        }
      } else {
        console.error(`Erro ao excluir formulário: ${response.statusText}`);
        alert(`Erro ao excluir formulário: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Erro ao excluir formulário:", error);
      alert("Erro ao excluir formulário.");
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl font-semibold mb-4">
        Criar Novo Formulário de Feedback
      </h2>

      <form onSubmit={handleSubmit} className="mb-8">
        <div className="mb-4">
          <input
            type="text"
            value={nomeFormulario}
            onChange={(e) => setNomeFormulario(e.target.value)}
            placeholder="Nome do Formulário"
            className="border border-gray-300 p-2 w-full rounded-lg"
          />
        </div>
        <div className="mb-4">
          <button
            type="button"
            onClick={adicionarPergunta}
            className="bg-sky text-white px-4 py-2 rounded-lg"
          >
            Adicionar Pergunta
          </button>
        </div>

        {perguntas.map((pergunta, index) => (
          <div key={index} className="mb-4">
            <input
              type="text"
              value={pergunta.pergunta}
              onChange={(e) =>
                atualizarPergunta(index, "pergunta", e.target.value)
              }
              placeholder="Digite a pergunta"
              className="border border-gray-300 p-2 w-full rounded-lg mb-2"
            />
            <select
              value={pergunta.tipoResposta}
              onChange={(e) =>
                atualizarPergunta(index, "tipoResposta", e.target.value)
              }
              className="border border-gray-300 p-2 w-full rounded-lg"
            >
              <option value="1 a 5">1 a 5</option>
              {/* Adicione mais opções de tipos de resposta conforme necessário */}
            </select>
            <button
              type="button"
              onClick={() => removerPergunta(index)}
              className="bg-vermelho text-white px-4 py-2 rounded-lg mt-2"
            >
              Remover Pergunta
            </button>
          </div>
        ))}

        <button
          type="submit"
          className="bg-darkgreen text-white px-4 py-2 rounded-lg"
        >
          Criar Formulário
        </button>
      </form>

      {linkUnico && (
        <div className="bg-white p-4 rounded-lg shadow-lg mb-4">
          <h3 className="text-lg font-semibold mb-2">
            Link Único do Formulário:
          </h3>
          <p className="text-sky mb-4">
            <a href={linkUnico} target="_blank" rel="noopener noreferrer">
              {linkUnico}
            </a>
          </p>
        </div>
      )}

      <h3 className="text-lg font-semibold mb-2">Formulários Criados:</h3>

      {formularios.map((formulario) => (
        <div
          key={formulario.id}
          className="bg-white p-4 rounded-lg shadow-lg mb-4"
        >
          <h4 className="text-lg font-semibold mb-2">{formulario.nome}</h4>

          {/* Verificar se existem perguntas e respostas associadas ao formulário */}
          {formularioDetalhes[formulario.id] &&
            formularioDetalhes[formulario.id].map((detalhes) => (
              <div key={detalhes.id}>
                <p>
                  <strong>Pergunta:</strong> {detalhes.pergunta}
                </p>

                {/* Exibir média aritmética das respostas */}
                <p>
                  <strong>Média das Respostas:</strong>{" "}
                  {calcularMediaAritmetica(detalhes.respostas)}
                </p>
                {/* Classificação da média */}
                <p>
                  <strong>Métrica:</strong>{" "}
                  {classificarMedia(
                    calcularMediaAritmetica(detalhes.respostas)
                  )}
                </p>
              </div>
            ))}

          {/* Adicione um botão para excluir o formulário */}
          <button
            onClick={() => excluirFormulario(formulario.id)}
            className="bg-vermelho text-white px-4 py-2 rounded-lg mt-2"
          >
            Excluir Formulário
          </button>
        </div>
      ))}
    </div>
  );
};

export default CriarFormulario;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const ItensCardapio = ({ cardapioId }) => {
  const [itens, setItens] = useState([]);
  const [novoNome, setNovoNome] = useState("");
  const [novaDescricao, setNovaDescricao] = useState("");
  const [novoPreco, setNovoPreco] = useState("");

  const fetchItens = async () => {
    try {
      const response = await axios.get(
        `https://api-foodflw-production.up.railway.app/cardapios/${cardapioId}/itens`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setItens(response.data);
    } catch (error) {
      console.error("Erro ao buscar itens do cardápio:", error);
      toast.error("Erro ao buscar itens do cardápio.");
    }
  };

  const adicionarItem = async () => {
    if (!novoNome || !novoPreco) {
      toast.warning("Preencha todos os campos obrigatórios.");
      return;
    }

    try {
      await axios.post(
        `https://api-foodflw-production.up.railway.app/cardapios/${cardapioId}/itens`,
        {
          nome: novoNome,
          descricao: novaDescricao,
          preco: parseFloat(novoPreco),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchItens();
      setNovoNome("");
      setNovaDescricao("");
      setNovoPreco("");
      toast.success("Item adicionado com sucesso!");
    } catch (error) {
      console.error("Erro ao adicionar item:", error);
      toast.error("Erro ao adicionar item.");
    }
  };

  useEffect(() => {
    fetchItens();
  }, [cardapioId]);

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-3xl font-semibold mb-6 text-center">
        Itens do Cardápio
      </h2>

      <div className="mb-6">
        <h4 className="text-xl font-semibold mb-4">Adicionar Novo Item</h4>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <input
            type="text"
            placeholder="Nome do Item"
            value={novoNome}
            onChange={(e) => setNovoNome(e.target.value)}
            className="block w-full px-3 py-2 border rounded"
          />
          <input
            type="text"
            placeholder="Descrição do Item"
            value={novaDescricao}
            onChange={(e) => setNovaDescricao(e.target.value)}
            className="block w-full px-3 py-2 border rounded"
          />
          <input
            type="number"
            placeholder="Preço"
            value={novoPreco}
            onChange={(e) => setNovoPreco(e.target.value)}
            className="block w-full px-3 py-2 border rounded"
          />
        </div>
        <button
          onClick={adicionarItem}
          className="mt-4 bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
        >
          Adicionar Item
        </button>
      </div>

      <div>
        {itens.length > 0 ? (
          itens.map((item) => (
            <div key={item.id} className="p-4 bg-white shadow-md mb-4 rounded">
              <span className="font-semibold text-lg">{item.nome}</span>
              <p>{item.descricao}</p>
              <p className="text-gray-600">
                R$ {parseFloat(item.preco).toFixed(2)}
              </p>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-600">Nenhum item no cardápio.</p>
        )}
      </div>
    </div>
  );
};

export default ItensCardapio;

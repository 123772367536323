import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Comida from "../../images/comida.jpg";

const CardapioPublico = () => {
  const { linkUnico } = useParams();
  const [cardapio, setCardapio] = useState(null);
  const [itensSelecionados, setItensSelecionados] = useState([]);
  const [modalItem, setModalItem] = useState(null);
  const [observacoes, setObservacoes] = useState("");
  const [nomeCliente, setNomeCliente] = useState("");
  const [telefoneCliente, setTelefoneCliente] = useState("");
  const [enderecoCliente, setEnderecoCliente] = useState("");

  useEffect(() => {
    const fetchCardapio = async () => {
      try {
        const res = await axios.get(
          `https://api-foodflw-production.up.railway.app/cardapios/publico/${linkUnico}`
        );
        setCardapio(res.data);
      } catch (error) {
        toast.error("Erro ao carregar o cardápio.");
        console.error(error);
      }
    };
    fetchCardapio();
  }, [linkUnico]);

  const abrirModal = (item) => {
    setModalItem(item);
  };

  const fecharModal = () => {
    setModalItem(null);
    setObservacoes("");
  };

  const adicionarAoCarrinho = (item) => {
    setItensSelecionados((prevItens) => [
      ...prevItens,
      { ...item, quantidade: 1, observacoes },
    ]);
    fecharModal();
  };

  const submeterPedido = async () => {
    if (!nomeCliente || !telefoneCliente || !enderecoCliente) {
      toast.warning("Por favor, preencha os dados do cliente.");
      return;
    }

    try {
      const itensPedido = itensSelecionados.map((item) => ({
        itemId: item.id,
        quantidade: item.quantidade,
        observacoes: item.observacoes,
      }));

      await axios.post(
        "https://api-foodflw-production.up.railway.app/pedidos",
        {
          cardapioId: cardapio.cardapio.id,
          itens: itensPedido,
          nome_cliente: nomeCliente,
          telefone_cliente: telefoneCliente,
          endereco_cliente: enderecoCliente,
        }
      );

      toast.success("Pedido submetido com sucesso!");
      setItensSelecionados([]);
      setNomeCliente("");
      setTelefoneCliente("");
      setEnderecoCliente("");
    } catch (error) {
      toast.error("Erro ao submeter o pedido.");
      console.error(error);
    }
  };

  if (!cardapio) {
    return <p>Carregando cardápio...</p>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6 text-center">{cardapio.nome}</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {cardapio.itens.map((item) => (
          <div
            key={item.id}
            className="flex items-center border p-2 rounded shadow"
            onClick={() => abrirModal(item)}
          >
            <img
              src={Comida} // Substitua por uma URL padrão caso a imagem não exista
              alt={item.nome}
              className="w-20 h-20 object-cover rounded-lg mr-4"
            />
            <div>
              <h3 className="text-lg font-semibold">{item.nome}</h3>
              <p className="text-cinzaescuro text-sm">{item.descricao}</p>
              <p className="text-md font-bold mt-1">R$ {item.preco}</p>
            </div>
          </div>
        ))}
      </div>

      {modalItem && (
        <div className="fixed inset-0 bg-preto bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-11/12 md:w-1/3">
            <img
              src={Comida}
              alt={modalItem.nome}
              className="w-full h-48 object-cover rounded mb-4"
            />
            <h3 className="text-xl font-semibold mb-2">{modalItem.nome}</h3>
            <p className="text-cinzaescuro mb-4">{modalItem.descricao}</p>

            <label className="block text-vermelho font-semibold mb-2">
              Alguma observação?
            </label>
            <textarea
              value={observacoes}
              onChange={(e) => setObservacoes(e.target.value)}
              placeholder="Adicione as observações aqui..."
              maxLength={140}
              className="w-full p-2 border rounded mb-4"
            />

            <button
              onClick={() => adicionarAoCarrinho(modalItem)}
              className="bg-lightgreen text-white w-full py-2 rounded hover:bg-darkgreen font-semibold"
            >
              Adicionar R$ {modalItem.preco}
            </button>
            <button
              onClick={fecharModal}
              className="mt-2 text-center text-vermelho w-full py-2 rounded font-semibold"
            >
              Fechar
            </button>
          </div>
        </div>
      )}

      {itensSelecionados.length > 0 && (
        <div className="mt-6">
          <h2 className="text-2xl font-bold mb-4">Carrinho</h2>
          <ul>
            {itensSelecionados.map((item, index) => (
              <li key={index} className="mb-2">
                {item.nome} - {item.quantidade}x
                {item.observacoes && (
                  <p className="text-sm text-cinzaescuro">
                    Obs: {item.observacoes}
                  </p>
                )}
              </li>
            ))}
          </ul>

          <div className="mt-4">
            <h4 className="text-xl font-semibold mb-2">Dados do Cliente</h4>
            <input
              type="text"
              placeholder="Nome"
              value={nomeCliente}
              onChange={(e) => setNomeCliente(e.target.value)}
              className="block w-full px-3 py-2 mb-2 border rounded"
            />
            <input
              type="text"
              placeholder="Telefone"
              value={telefoneCliente}
              onChange={(e) => setTelefoneCliente(e.target.value)}
              className="block w-full px-3 py-2 mb-2 border rounded"
            />
            <input
              type="text"
              placeholder="Endereço"
              value={enderecoCliente}
              onChange={(e) => setEnderecoCliente(e.target.value)}
              className="block w-full px-3 py-2 mb-2 border rounded"
            />
          </div>

          <button
            onClick={submeterPedido}
            className="bg-lightgreen text-white px-4 py-2 rounded hover:bg-darkgreen mt-4 w-full"
          >
            Finalizar Pedido
          </button>
        </div>
      )}
    </div>
  );
};

export default CardapioPublico;

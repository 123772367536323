import React from "react";
import { Link } from "react-router-dom";
import { FaChartLine, FaRegSmile, FaUserTimes } from "react-icons/fa";
import {
  MdOutlineSecurity,
  MdSchedule,
  MdShoppingBasket,
} from "react-icons/md";

const CardGestaoEmpresarial = ({ title, description, icon, link }) => {
  return (
    <div className="bg-gradient-to-r from-green to-blue-500 border-2  rounded-lg shadow-lg p-4 hover:shadow-xl transition-shadow">
      <div className="flex items-center mb-4">
        <div className="text-3xl text-sky-500">{icon}</div>
        <h3 className="text-2xl font-semibold ml-2">{title}</h3>
      </div>
      <p className="text-gray-700 mb-4">{description}</p>
      {link && (
        <Link
          to={link}
          className="text-white bg-darkgreen border-2 rounded-lg p-2  hover:bg-ultradarkgreen font-semibold"
        >
          Acessar
        </Link>
      )}
    </div>
  );
};

const GestaoEmpresarial = () => {
  return (
    <div className="p-8">
      <h2 className="text-3xl font-bold mb-8 text-center">
        Gestão Empresarial
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <CardGestaoEmpresarial
          title="Percentual de crescimento"
          description="Visualize o percentual de crescimento da sua empresa por período específico."
          icon={<FaChartLine />}
          link="/crescimento"
        />
        <CardGestaoEmpresarial
          title="Ticket médio"
          description="Visualize o ticket médio das vendas em determinado período."
          icon={<MdShoppingBasket />}
          link="/ticket-medio"
        />

        <CardGestaoEmpresarial
          title="Lucro bruto"
          description="Analise o lucro bruto de sua empresa em períodos específicos."
          icon={<FaChartLine />}
          link="/lucro-bruto"
        />
        <CardGestaoEmpresarial
          title="Clientes"
          description="Base de dados de clientes."
          icon={<MdSchedule />}
          link="/clientes"
        />
        <CardGestaoEmpresarial
          title="Satisfação do cliente"
          description="Acompanhe a satisfação dos clientes com suas compras e serviços."
          icon={<FaRegSmile />}
          link="/satisfacao-cliente"
        />
        <CardGestaoEmpresarial
          title="Segurança alimentar"
          description="Indicadores relacionados à segurança alimentar em seu negócio."
          icon={<MdOutlineSecurity />}
          link="/seguranca-alimentar"
        />
        <CardGestaoEmpresarial
          title="Funcionários demitidos"
          description="Visualize a lista de funcionários demitidos e informações relevantes."
          icon={<FaUserTimes />}
          link="/funcionarios-demitidos"
        />
      </div>
    </div>
  );
};

export default GestaoEmpresarial;

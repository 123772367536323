import React, { useState, useEffect } from "react";
import axios from "axios";

const TicketMedio = () => {
  const [ticketMedio, setTicketMedio] = useState(null);
  const [valorTotalVendas, setValorTotalVendas] = useState(null);
  const [somaQuantidadesVendidas, setSomaQuantidadesVendidas] = useState(null);

  useEffect(() => {
    const fetchDadosTicketMedio = async () => {
      try {
        const response = await axios.get(
          "https://api-foodflw-production.up.railway.app/dados-ticket-medio",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          const { valor_total_vendas, soma_quantidades_vendidas } =
            response.data;

          const ticketMedioCalculado =
            valor_total_vendas / soma_quantidades_vendidas;

          setValorTotalVendas(valor_total_vendas);
          setSomaQuantidadesVendidas(soma_quantidades_vendidas);
          setTicketMedio(ticketMedioCalculado);
        }
      } catch (error) {
        console.error("Erro ao obter dados para ticket médio:", error);
      }
    };

    fetchDadosTicketMedio();
  }, []);

  const formattedValorTotalVendas =
    valorTotalVendas !== null ? parseFloat(valorTotalVendas).toFixed(2) : "N/A";

  return (
    <div className="p-8 bg-white mt-16 shadow-lg rounded-lg max-w-lg mx-auto border border-lightgreen mb-16">
      <h2 className="text-3xl font-semibold text-center text-darkgreen mb-6">
        Ticket Médio🧾
      </h2>

      {/* Informações sobre o cálculo do ticket médio */}
      <div className="bg-green p-4 rounded-lg shadow-sm mb-4">
        <p className="text-sky mb-2">
          <strong>Valor total de vendas:</strong> R${formattedValorTotalVendas}
        </p>
        <p className="text-sky">
          <strong>Soma de quantidades vendidas:</strong>{" "}
          {somaQuantidadesVendidas}
        </p>
      </div>

      {/* Exibição do ticket médio */}
      <div className="bg-green p-4 rounded-lg shadow-sm">
        <p className="text-sky font-semibold text-center">
          O ticket médio do restaurante é:{" "}
          <strong className="text-darkgreen font-semibold ">
            {ticketMedio !== null
              ? `R$${ticketMedio.toFixed(2)}`
              : "Carregando..."}
          </strong>
        </p>
      </div>
    </div>
  );
};

export default TicketMedio;

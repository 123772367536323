import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Estoque from "../Estoque";
import VendaPrato from "../VendaPratos";
import ListaReceitas from "../MostrarReceitas";
import Sidebar from "../Card";
import TicketMedio from "../TicketMedio";
import CalculaLucroBruto from "../CalcularLucroBruto";
import PratosMaisVendidos from "../PratosMaisVendidos";

const MeuRestaurantePage = () => {
  const [ingredientes, setIngredientes] = useState([]);
  const [receitas, setReceitas] = useState([]);
  const [mostrarReceitas, setMostrarReceitas] = useState(false);
  const [mostrarEstoque] = useState(false);
  const [mostrarBotaoGerarPDF] = useState(false);
  const [mostrarVendaPratos] = useState(false);

  useEffect(() => {
    fetchIngredientes();
    fetchReceitas();
  }, []);

  const fetchIngredientes = async () => {
    try {
      const res = await axios.get(
        "https://api-foodflw-production.up.railway.app/ingredientes",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setIngredientes(res.data);
    } catch (error) {
      console.error("Erro ao obter ingredientes:", error);
    }
  };

  const fetchReceitas = async () => {
    try {
      const res = await axios.get(
        "https://api-foodflw-production.up.railway.app/receitas",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setReceitas(res.data);
    } catch (error) {
      console.error("Erro ao obter receitas:", error);
    }
  };

  const excluirReceita = async (id) => {
    try {
      await axios.delete(
        `https://api-foodflw-production.up.railway.app/receitas/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchReceitas();
    } catch (error) {
      console.error("Erro ao excluir receita:", error);
    }
  };

  return (
    <div className="flex h-screen">
      {/* Sidebar - sempre visível */}
      <div className="w-1/6">
        <Sidebar onMenuItemClick={() => {}} />
      </div>

      {/* Conteúdo principal - visível apenas em telas maiores que md (medium) */}
      <div className="w-5/6 p-4 md:p-10 overflow-y-auto">
        {/* Grid para os gráficos */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Ticket Médio */}
          <div className="p-4 bg-white shadow-md h-2/3 rounded-lg">
            <TicketMedio />
            <Link
              to="/ticket-medio"
              className="block mt-2 text-center text-sky hover:underline"
            >
              Ir para Ticket Médio
            </Link>
          </div>

          {/* Lucro Bruto */}
          <div className="p-4 bg-white shadow-md rounded-lg">
            <CalculaLucroBruto />
            <Link
              to="/lucro-bruto"
              className="block mt-2 text-center text-sky hover:underline"
            >
              Ir para Lucro Bruto
            </Link>
          </div>

          {/* Pratos Mais Vendidos */}
          <div className="p-4 bg-white shadow-md -mt-44 rounded-lg">
            <PratosMaisVendidos />
            <Link
              to="/crescimento"
              className="block mt-2 text-center text-sky hover:underline"
            >
              Ir para Pratos Mais Vendidos
            </Link>
          </div>
        </div>

        {/* Outras seções condicionais */}
        <div className="flex flex-wrap justify-center mt-6">
          {mostrarBotaoGerarPDF && (
            <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
              <ListaReceitas
                receitas={receitas}
                mostrarReceitas={mostrarReceitas}
                setMostrarReceitas={setMostrarReceitas}
                excluirReceita={excluirReceita}
                fetchReceitas={fetchReceitas}
              />
              <Link
                to="/lista-receitas"
                className="block mt-2 text-center text-blue-500 hover:underline"
              >
                Ir para Lista de Receitas
              </Link>
            </div>
          )}
          {mostrarEstoque && (
            <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
              <Estoque ingredientes={ingredientes} />
              <Link
                to="/estoque"
                className="block mt-2 text-center text-blue-500 hover:underline"
              >
                Ir para Estoque
              </Link>
            </div>
          )}
          {mostrarVendaPratos && (
            <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
              <VendaPrato />
              <Link
                to="/venda-prato"
                className="block mt-2 text-center text-sky hover:underline"
              >
                Ir para Venda de Pratos
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MeuRestaurantePage;

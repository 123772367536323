import React, { useEffect, useState } from "react";
import axios from "axios";
import jsPDF from "jspdf";

const PedidosPratosVendidos = () => {
  const [pratosVendidos, setPratosVendidos] = useState([]);

  // Função para buscar os pedidos de pratos vendidos
  useEffect(() => {
    const fetchPratosVendidos = async () => {
      try {
        const response = await axios.get(
          "https://api-foodflw-production.up.railway.app/pratos-vendidos",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Autenticação
            },
          }
        );
        setPratosVendidos(response.data);
      } catch (error) {
        console.error("Erro ao buscar pratos vendidos:", error);
      }
    };

    fetchPratosVendidos();
  }, []);

  const gerarComandaPDF = (mesa) => {
    const doc = new jsPDF();

    // Adicionar logo no PDF (se tiver o link da imagem, pode ser inserido aqui)
    // doc.addImage(logo, 'PNG', 70, 5, 60, 30); /

    // Adicionar título e bordas para a tabela
    doc.setFontSize(16);
    doc.text("Comanda", 105, 20, { align: "center" });

    // Linha para Data e Mesa
    doc.setLineWidth(0.5);
    doc.rect(15, 30, 180, 10); // Bordas para data e mesa
    doc.setFontSize(12);
    doc.text(`Data: ${new Date().toLocaleDateString()}`, 20, 37);
    doc.text(`Mesa: ${mesa}`, 150, 37);

    // Cabeçalhos da tabela
    doc.rect(15, 50, 180, 10); // Bordas para os cabeçalhos
    doc.text("QT", 20, 57);
    doc.text("DESCRIÇÃO", 60, 57);
    doc.text("VALOR", 160, 57);

    let yPosition = 70;

    // Gerar os itens da comanda
    pratosVendidos
      .filter((prato) => prato.mesa === mesa)
      .forEach((prato) => {
        doc.rect(15, yPosition - 5, 180, 10); // Bordas para cada linha da tabela
        doc.text(`${prato.quantidade}`, 20, yPosition);
        doc.text(`${prato.prato_nome}`, 60, yPosition);
        doc.text(`R$ ${prato.valor_total}`, 160, yPosition);
        yPosition += 10;
      });

    const total = pratosVendidos
      .filter((prato) => prato.mesa === mesa)
      .reduce((total, prato) => total + prato.valor_total, 0);

    // Linha final para o total
    yPosition += 10;
    doc.text(`Total: R$ ${total}`, 160, yPosition);

    // Baixar o PDF
    doc.save(`comanda_mesa_${mesa}.pdf`);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Pratos Vendidos</h1>

      {pratosVendidos.length === 0 ? (
        <p>Nenhum prato vendido encontrado.</p>
      ) : (
        // Agrupar por mesa
        Array.from(new Set(pratosVendidos.map((prato) => prato.mesa))).map(
          (mesa) => (
            <div key={mesa} className="mb-6">
              <h2 className="text-xl font-semibold">Mesa: {mesa}</h2>
              {pratosVendidos
                .filter((prato) => prato.mesa === mesa)
                .map((prato, index) => (
                  <div
                    key={index}
                    className="bg-white shadow-md rounded-lg p-6 mb-4 border border-gray-200"
                  >
                    <div className="flex justify-between items-center mb-2">
                      <h2 className="text-lg font-semibold">
                        Prato: {prato.prato_nome}
                      </h2>
                      <span className="text-sm text-gray-600">
                        Quantidade: {prato.quantidade}
                      </span>
                    </div>

                    <div className="mb-4">
                      <h3 className="text-md font-medium">
                        Detalhes da Venda:
                      </h3>
                      <p className="text-gray-700">
                        Custo Total: R${prato.custo_total}
                      </p>
                      <p className="text-gray-700">Lucro: R${prato.lucro}</p>
                      <p className="text-gray-700">
                        Valor Total Vendido: R${prato.valor_total}
                      </p>
                    </div>
                  </div>
                ))}
              <button
                onClick={() => gerarComandaPDF(mesa)}
                className="bg-darkgreen text-white font-bold py-2 px-4 rounded"
              >
                Baixar Comanda Mesa {mesa}
              </button>
            </div>
          )
        )
      )}
    </div>
  );
};

export default PedidosPratosVendidos;

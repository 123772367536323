import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const FormularioPublico = () => {
  const { linkUnico } = useParams(); // Obter o link único da URL
  const [formulario, setFormulario] = useState(null);
  const [respostas, setRespostas] = useState([]);
  const [formularioId, setFormularioId] = useState(null);

  // Função para buscar o formulário com suas perguntas
  useEffect(() => {
    const fetchFormulario = async () => {
      try {
        const response = await axios.get(
          `https://api-foodflw-production.up.railway.app/formularios/publico/${linkUnico}`
        );
        const formularioData = response.data;

        // Armazene o ID do formulário
        setFormularioId(formularioData.id);

        const respostasIniciais = formularioData.perguntas.map((pergunta) => ({
          perguntaId: pergunta.id,
          resposta: "",
        }));

        setFormulario(formularioData);
        setRespostas(respostasIniciais);
      } catch (error) {
        console.error("Erro ao obter formulário público:", error);
      }
    };

    fetchFormulario();
  }, [linkUnico]);

  const atualizarResposta = (perguntaId, novaResposta) => {
    // Converte a resposta para número e verifica se está entre 1 e 5
    const valor = parseFloat(novaResposta);
    if (isNaN(valor) || valor < 1 || valor > 5) {
      toast.error("Por favor, insira um valor entre 1 e 5.");
      return;
    }

    // Atualiza a resposta se o valor estiver dentro do intervalo desejado
    setRespostas((respostasAnteriores) =>
      respostasAnteriores.map((resposta) =>
        resposta.perguntaId === perguntaId
          ? { ...resposta, resposta: valor }
          : resposta
      )
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formularioId) {
      console.error("formularioId não está definido.");
      alert(
        "Erro interno: ID do formulário não está disponível. Tente recarregar a página."
      );
      return;
    }

    // Validação das respostas antes de enviá-las
    const respostasValidas = respostas.filter((resposta) => {
      return (
        resposta.perguntaId !== undefined &&
        !isNaN(parseFloat(resposta.resposta))
      );
    });

    if (respostasValidas.length !== respostas.length) {
      alert("Por favor, preencha todas as respostas com valores válidos.");
      return;
    }

    try {
      // Envio de respostas para o backend com `formularioId`
      const response = await axios.post(
        `https://api-foodflw-production.up.railway.app/formularios/${formularioId}/respostas`,
        { respostas: respostasValidas }
      );

      if (response.status === 201) {
        toast.success("Respostas enviadas com sucesso!");
      } else {
        console.error(`Erro ao enviar respostas: ${response.statusText}`);
        alert(`Erro ao enviar respostas: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Erro ao enviar respostas ao formulário:", error);
      alert("Erro ao enviar respostas ao formulário.");
    }
  };

  if (!formulario) {
    return <p className="text-sky text-lg font-medium">Carregando...</p>;
  }

  return (
    <div className="p-4 text-sky">
      <h2 className="text-2xl font-bold mb-4">{formulario.nome}</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {formulario.perguntas.map((pergunta) => (
          <div key={pergunta.id} className="space-y-2">
            <label className="block font-medium">{pergunta.pergunta}</label>
            <input
              type="number"
              value={
                respostas.find((r) => r.perguntaId === pergunta.id)?.resposta ||
                ""
              }
              onChange={(e) => {
                atualizarResposta(pergunta.id, e.target.value);
              }}
              required
              min={1}
              max={5}
              className="w-full p-2 rounded bg-green text-sky"
            />
          </div>
        ))}
        <button
          type="submit"
          className="bg-lightgreen text-white font-medium py-2 px-4 rounded hover:bg-limegreen"
        >
          Enviar Respostas
        </button>
      </form>
    </div>
  );
};

export default FormularioPublico;

import React, { useState } from "react";
import { FaTrashAlt } from "react-icons/fa";

const ReceitaCard = ({ receita, onExcluir, onEditar }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(receita);

  // Função para lidar com mudanças no formulário de edição
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  // Função para lidar com o cancelamento da edição
  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditData(receita);
  };

  // Função para salvar a edição
  const handleSaveEdit = () => {
    onEditar(editData);
    setIsEditing(false);
  };

  return (
    <div className="bg-gradient-to-r from-darkgreen via-ultradarkgreen to-darkgreen p-1 rounded-lg shadow-lg mb-6">
      <div className="bg-white p-6 rounded-lg">
        {isEditing ? (
          <div>
            {/* Formulário de edição */}
            <input
              type="text"
              name="nome"
              value={editData.nome}
              onChange={handleChange}
              className="block w-full px-3 py-2 mb-4 border border-cinza rounded-lg"
              placeholder="Nome da Receita"
            />
            <textarea
              name="descricao"
              value={editData.descricao}
              onChange={handleChange}
              className="block w-full px-3 py-2 mb-4 border border-gray-300 rounded-lg"
              placeholder="Descrição"
            />
            <textarea
              name="modo_preparo"
              value={editData.modo_preparo}
              onChange={handleChange}
              className="block w-full px-3 py-2 mb-4 border border-gray-300 rounded-lg"
              placeholder="Modo de Preparo"
            />
            {/* Botões de salvar e cancelar */}
            <div className="flex justify-end">
              <button
                onClick={handleSaveEdit}
                className="bg-sky text-white px-4 py-2 rounded-lg mr-2 hover:bg-blue-600"
              >
                Salvar
              </button>
              <button
                onClick={handleCancelEdit}
                className="bg-vermelho text-white px-4 py-2 rounded-lg hover:bg-red-600"
              >
                Cancelar
              </button>
            </div>
          </div>
        ) : (
          <div>
            {/* Exibição dos dados da receita */}
            <h4 className="text-2xl font-bold mb-4 text-gray-800">
              {receita.nome}
            </h4>
            <p className="text-sm text-gray-600 mb-4">{receita.descricao}</p>
            <p className="text-sm text-gray-600 mb-4">
              <strong>Modo de Preparo:</strong> {receita.modo_preparo}
            </p>

            {/* Custo e lucro */}
            <div className="text-sm text-gray-600 mb-4">
              <p>
                <strong>Custo Total:</strong> R${" "}
                {parseFloat(receita.custo_total).toFixed(2)}
              </p>
              <p>
                <strong>Custo por Porção:</strong> R${" "}
                {parseFloat(receita.valor_porcoes).toFixed(2)}
              </p>
              <p>
                <strong>
                  Para obter {receita.markupporcentagem}% de lucro, cobre:
                </strong>{" "}
                R$ {parseFloat(receita.lucro).toFixed(2)}
              </p>
              <p>
                <strong>Lucro por porção:</strong> R${" "}
                {parseFloat(receita.lucro_por_porcao).toFixed(2)}
              </p>
            </div>

            {/* Lista de ingredientes */}
            <h5 className="font-semibold mt-4 mb-2 text-gray-800">
              Ingredientes:
            </h5>
            <ul className="list-disc pl-5 text-gray-600">
              {receita.ingredientes.map((ingrediente) => (
                <li key={ingrediente.id}>
                  {ingrediente.nome} - Quantidade: {ingrediente.quantidade}{" "}
                  {ingrediente.unidade}
                </li>
              ))}
            </ul>

            {/* Botões de edição e exclusão */}
            <div className="flex justify-end mt-6">
              <button
                onClick={() => onExcluir(receita.id)}
                className="text-vermelho hover:text-red-700"
              >
                <FaTrashAlt size={18} />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReceitaCard;

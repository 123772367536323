import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";

const AdicionarReceitaForm = ({
  fetchReceitas,
  ingredientes = [],
  setIngredientesSelecionados,
  ingredientesSelecionados = [],
}) => {
  const [nomeReceita, setNomeReceita] = useState("");
  const [descricao, setDescricao] = useState("");
  const [modoPreparo, setModoPreparo] = useState("");
  const [porcoes, setPorcoes] = useState("");
  const [termoPesquisa, setTermoPesquisa] = useState("");
  const [ingredienteSelecionado, setIngredienteSelecionado] = useState("");
  const [quantidadeIngrediente, setQuantidadeIngrediente] = useState("");
  const [lucro, setLucro] = useState("");
  const [custoTotal, setCustoTotal] = useState(0);
  const [markupPorcentagem, setMarkupPorcentagem] = useState(0);
  const [precoTotal, setPrecoTotal] = useState(0);
  const [precoFinal, setPrecoFinal] = useState(0);
  const [opcaoCalculo, setOpcaoCalculo] = useState("markup");
  const [valorInput, setValorInput] = useState("");
  const [margemLucro, setMargemLucro] = useState(0);

  const adicionarReceita = async () => {
    try {
      // Calcule o custo total com base nos ingredientes selecionados
      const custoTotal = ingredientesSelecionados.reduce(
        (total, ingrediente) => {
          const ingredienteData = ingredientes.find(
            (item) => item.id === ingrediente.id
          );
          const custoIngrediente = ingredienteData
            ? ingredienteData.custo * ingrediente.quantidade
            : 0;
          return total + custoIngrediente;
        },
        0
      );

      const custoPorPorcao =
        porcoes !== 0 ? custoTotal / parseFloat(porcoes) : 0;

      // Preparar dados para enviar à API
      const dadosReceita = {
        nome: nomeReceita,
        descricao,
        modo_preparo: modoPreparo,
        ingredientes: ingredientesSelecionados.map((ingrediente) => ({
          ingrediente_id: ingrediente.id,
          quantidade: ingrediente.quantidade,
          unidade: ingrediente.unidade,
        })),
        custo: custoTotal,
        custo_por_porcao: custoPorPorcao,
        porcoes: parseFloat(porcoes),
        percentual_lucro: markupPorcentagem,
        markupPorcentagem: margemLucro,
      };

      // Verifique se todos os dados obrigatórios estão presentes
      if (
        !nomeReceita ||
        !descricao ||
        !modoPreparo ||
        !porcoes ||
        !ingredientesSelecionados.length
      ) {
        toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }

      // Fazer a solicitação POST para adicionar a receita
      const response = await axios.post(
        "https://api-foodflw-production.up.railway.app/receitas",
        dadosReceita,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        toast.success("Receita adicionada com sucesso!");
        fetchReceitas(); // Atualize a lista de receitas
        // Limpe os campos do formulário após o sucesso
        setNomeReceita("");
        setDescricao("");
        setModoPreparo("");
        setPorcoes("");
        setIngredientesSelecionados([]);
        setLucro("");
        setMarkupPorcentagem(0);
        setPrecoTotal(0);
        setMargemLucro(0);
      } else {
        toast.error("Erro ao adicionar receita.");
      }
    } catch (error) {
      console.error("Erro ao adicionar receita:", error);
      toast.error("Erro ao adicionar receita. Tente novamente.");
    }
  };

  const adicionarIngredienteNaReceita = () => {
    const quantidade = quantidadeIngrediente.replace(",", ".");
    const ingrediente = {
      id: ingredienteSelecionado,
      quantidade: quantidade,
    };
    setIngredientesSelecionados([...ingredientesSelecionados, ingrediente]);
    setIngredienteSelecionado("");
    setQuantidadeIngrediente("");
    toast.success("Ingrediente adicionado com sucesso!");
  };

  const handleRemoverIngrediente = (id) => {
    setIngredientesSelecionados(
      ingredientesSelecionados.filter((ingrediente) => ingrediente.id !== id)
    );
    toast.success("Ingrediente removido com sucesso!");
  };

  useEffect(() => {
    const total = ingredientesSelecionados.reduce(
      (acc, ingredienteSelecionado) => {
        const ingrediente = ingredientes.find(
          (item) => item.id === parseInt(ingredienteSelecionado.id)
        );
        if (ingrediente) {
          const valor = parseFloat(ingrediente.valor);
          const fatorCorrecao = parseFloat(ingrediente.fator_correção);

          const quantidade = parseFloat(ingredienteSelecionado.quantidade);

          if (!isNaN(valor) && !isNaN(fatorCorrecao) && !isNaN(quantidade)) {
            let custoIngrediente = quantidade * valor;

            if (fatorCorrecao !== 0) {
              custoIngrediente *= fatorCorrecao;
            }

            acc += custoIngrediente;
          }
        }
        return acc;
      },
      0
    );

    setCustoTotal(total);
  }, [ingredientesSelecionados, ingredientes]);

  useEffect(() => {
    if (valorInput === "") return;

    const valor = parseFloat(valorInput);
    if (isNaN(valor)) return;

    if (custoTotal === 0) return;

    switch (opcaoCalculo) {
      case "markup":
        const precoFinalMarkup = custoTotal * (1 + valor / 100);
        setPrecoFinal(precoFinalMarkup.toFixed(2));
        setMargemLucro(
          (((precoFinalMarkup - custoTotal) / precoFinalMarkup) * 100).toFixed(
            2
          )
        );
        setMarkupPorcentagem(valor);
        break;
      case "precoFinal":
        const markup = ((valor / custoTotal - 1) * 100).toFixed(2);
        const margemLucroPrecoFinal = (
          ((valor - custoTotal) / valor) *
          100
        ).toFixed(2);
        setMarkupPorcentagem(markup);
        setPrecoFinal(valor.toFixed(2));
        setMargemLucro(margemLucroPrecoFinal);
        break;
      case "margemLucro":
        const precoFinalMargemLucro = custoTotal / (1 - valor / 100);
        const markupMargemLucro = (
          (precoFinalMargemLucro / custoTotal - 1) *
          100
        ).toFixed(2);
        setPrecoFinal(precoFinalMargemLucro.toFixed(2));
        setMarkupPorcentagem(markupMargemLucro);
        setMargemLucro(valor);
        break;
      default:
        break;
    }
  }, [opcaoCalculo, valorInput, custoTotal]);

  return (
    <div className="p-10  rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold text-darkgreen mb-6 text-center">
        Formulário Para Adicionar Receita
      </h2>

      <fieldset className="mb-6 p-4 border border-darkgreen rounded-lg">
        <legend className="text-xl font-semibold text-darkgreen">
          Detalhes da Receita
        </legend>
        <div className="mb-4">
          <label
            className="block font-semibold text-darkgreen mb-1"
            htmlFor="nomeReceita"
          >
            Nome da Receita:
          </label>
          <input
            id="nomeReceita"
            type="text"
            placeholder="Nome da Receita"
            value={nomeReceita}
            onChange={(e) => setNomeReceita(e.target.value)}
            className="w-full px-4 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
          />
        </div>
        <div className="mb-4">
          <label
            className="block font-semibold text-darkgreen mb-1"
            htmlFor="descricaoReceita"
          >
            Descrição:
          </label>
          <input
            id="descricaoReceita"
            type="text"
            placeholder="Descrição"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            className="w-full px-4 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
          />
        </div>
        <div className="mb-4">
          <label
            className="block font-semibold text-darkgreen mb-1"
            htmlFor="modoPreparo"
          >
            Modo de Preparo:
          </label>
          <textarea
            id="modoPreparo"
            placeholder="Modo de Preparo"
            value={modoPreparo}
            onChange={(e) => setModoPreparo(e.target.value)}
            className="w-full px-4 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
            rows={4}
          />
        </div>
        <div className="mb-4">
          <label
            className="block font-semibold text-darkgreen mb-1"
            htmlFor="porcoesReceita"
          >
            Porções:
          </label>
          <input
            id="porcoesReceita"
            type="number"
            placeholder="Número de porções"
            value={porcoes}
            onChange={(e) => setPorcoes(e.target.value)}
            className="w-full px-4 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
          />
        </div>
      </fieldset>

      <fieldset className="mb-6 p-4 border border-darkgreen rounded-lg">
        <legend className="text-xl font-semibold text-darkgreen">
          Ingredientes
        </legend>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Pesquisar Ingredientes... 🔎"
            value={termoPesquisa}
            onChange={(e) => setTermoPesquisa(e.target.value)}
            className="w-full px-4 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
          />
        </div>
        {termoPesquisa && (
          <div className="mb-4">
            {ingredientes
              .filter((ingrediente) =>
                ingrediente.nome
                  .toLowerCase()
                  .includes(termoPesquisa.toLowerCase())
              )
              .map((ingrediente) => (
                <label key={ingrediente.id} className="flex items-center mb-2">
                  <input
                    type="radio"
                    value={ingrediente.id}
                    checked={ingrediente.id === ingredienteSelecionado}
                    onChange={() => setIngredienteSelecionado(ingrediente.id)}
                    className="mr-2"
                  />
                  <span className="text-darkgreen">{ingrediente.nome}</span>
                </label>
              ))}
          </div>
        )}

        <div className="mb-4">
          <label
            className="block font-semibold text-darkgreen mb-1"
            htmlFor="quantidadeIngrediente"
          >
            Quantidade:
          </label>
          <input
            id="quantidadeIngrediente"
            type="number"
            value={quantidadeIngrediente}
            onChange={(e) => setQuantidadeIngrediente(e.target.value)}
            className="w-full px-4 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
          />
        </div>

        <button
          onClick={adicionarIngredienteNaReceita}
          className="bg-darkgreen text-white font-semibold px-4 py-2 rounded-md hover:bg-ultradarkgreen"
        >
          Adicionar Ingrediente
        </button>
      </fieldset>

      <fieldset className="mb-6 p-4 border border-darkgreen rounded-lg">
        <legend className="text-xl font-semibold text-darkgreen">
          Ingredientes Selecionados
        </legend>
        <ul className="list-disc ml-4">
          {ingredientesSelecionados.map((ingredienteSelecionado) => {
            const ingrediente = ingredientes.find(
              (item) => item.id === parseInt(ingredienteSelecionado.id)
            );
            return (
              <li key={ingredienteSelecionado.id} className="flex items-center">
                <span className="text-darkgreen">
                  {ingrediente?.nome} - {ingredienteSelecionado.quantidade}{" "}
                  {ingrediente?.unidade}
                </span>
                <FaTimes
                  onClick={() =>
                    handleRemoverIngrediente(ingredienteSelecionado.id)
                  }
                  className="text-vermelho cursor-pointer ml-4"
                />
              </li>
            );
          })}
        </ul>
      </fieldset>

      <fieldset className="mb-6 p-4 border border-darkgreen rounded-lg">
        <legend className="text-xl font-semibold text-darkgreen">
          Cálculo de Preço
        </legend>
        <div className="mb-4">
          <label
            className="block font-semibold text-darkgreen mb-1"
            htmlFor="opcaoCalculo"
          >
            Escolha uma opção para calcular:
          </label>
          <select
            id="opcaoCalculo"
            value={opcaoCalculo}
            onChange={(e) => setOpcaoCalculo(e.target.value)}
            className="w-full px-4 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
          >
            <option value="markup">Markup</option>
            <option value="precoFinal">Preço Final</option>
            <option value="margemLucro">Margem de Lucro</option>
          </select>
        </div>

        <div className="mb-4">
          <label
            className="block font-semibold text-darkgreen mb-1"
            htmlFor="valorInput"
          >
            {`Preencha o ${opcaoCalculo}:`}
          </label>
          <input
            id="valorInput"
            type="number"
            value={valorInput}
            onChange={(e) => setValorInput(e.target.value)}
            className="w-full px-4 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
          />
        </div>

        <p className="text-darkgreen font-semibold mb-2">
          Markup: <span>{markupPorcentagem}%</span>
        </p>
        <p className="text-darkgreen font-semibold mb-2">
          Preço Final: <span>R$ {precoFinal}</span>
        </p>
        <p className="text-darkgreen font-semibold">
          Margem de Lucro: <span>{margemLucro}%</span>
        </p>
      </fieldset>

      <button
        onClick={adicionarReceita}
        className="bg-darkgreen text-white font-semibold px-4 py-2 rounded-md hover:bg-ultradarkgreen"
      >
        Adicionar Receita
      </button>
    </div>
  );
};

export default AdicionarReceitaForm;

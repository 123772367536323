import React, { useState } from "react";
import { toast } from "react-toastify";

const EmployeeAdmissionForm = () => {
  const [formData, setFormData] = useState({
    empresa: "",
    funcionario: "",
    data_admissao: "",
    funcao: "",
    salario: "",
    pagamento: "",
    endereco: "",
    numero: "",
    bairro: "",
    cidade: "",
    cep: "",
    estado: "",
    data_nascimento: "",
    local_nascimento: "",
    uf_nascimento: "",
    rg: "",
    orgao_emissor: "",
    uf_rg: "",
    carteira_trabalho: "",
    serie: "",
    pis: "",
    cpf: "",
    telefone: "",
    titulo_eleitor: "",
    zona: "",
    secao: "",
    pai: "",
    mae: "",
    conjugue: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = "https://api-foodflw-production.up.railway.app/funcionarios";

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Funcionário adicionado com sucesso!");
        handleReset();
      } else {
        const errorData = await response.json();
        console.error("Erro ao adicionar funcionário:", errorData);
        toast.error("Erro ao adicionar funcionário.");
      }
    } catch (error) {
      console.error("Erro ao enviar a requisição:", error);
      toast.error("Erro ao adicionar funcionário.");
    }
  };

  const handleReset = () => {
    setFormData({
      empresa: "",
      funcionario: "",
      data_admissao: "",
      funcao: "",
      salario: "",
      pagamento: "",
      endereco: "",
      numero: "",
      bairro: "",
      cidade: "",
      cep: "",
      estado: "",
      data_nascimento: "",
      local_nascimento: "",
      uf_nascimento: "",
      rg: "",
      orgao_emissor: "",
      uf_rg: "",
      carteira_trabalho: "",
      serie: "",
      pis: "",
      cpf: "",
      telefone: "",
      titulo_eleitor: "",
      zona: "",
      secao: "",
      pai: "",
      mae: "",
      conjugue: "",
    });
  };

  return (
    <div className="p-10  rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold text-darkgreen mb-6 text-center">
        Ficha de Admissão de Funcionário
      </h2>

      <form onSubmit={handleSubmit}>
        {/* Informações de Emprego */}
        <fieldset className="mb-6 p-4 border border-darkgreen rounded-lg">
          <legend className="text-xl font-semibold text-darkgreen">
            Informações de Emprego
          </legend>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="empresa"
              >
                Empresa:
              </label>
              <input
                id="empresa"
                name="empresa"
                type="text"
                value={formData.empresa}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="funcionario"
              >
                Funcionário(a):
              </label>
              <input
                id="funcionario"
                name="funcionario"
                type="text"
                value={formData.funcionario}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="data_admissao"
              >
                Data de Admissão:
              </label>
              <input
                id="data_admissao"
                name="data_admissao"
                type="date"
                value={formData.data_admissao}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="funcao"
              >
                Função:
              </label>
              <input
                id="funcao"
                name="funcao"
                type="text"
                value={formData.funcao}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="salario"
              >
                Salário:
              </label>
              <input
                id="salario"
                name="salario"
                type="number"
                value={formData.salario}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="pagamento"
              >
                Forma de Pagamento:
              </label>
              <select
                id="pagamento"
                name="pagamento"
                value={formData.pagamento}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              >
                <option value="">Selecione</option>
                <option value="hora">Hora</option>
                <option value="dia">Dia</option>
                <option value="mes">Mês</option>
              </select>
            </div>
          </div>
        </fieldset>

        {/* Endereço */}
        <fieldset className="mb-6 p-4 border border-darkgreen rounded-lg">
          <legend className="text-xl font-semibold text-darkgreen">
            Endereço
          </legend>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="endereco"
              >
                Endereço:
              </label>
              <input
                id="endereco"
                name="endereco"
                type="text"
                value={formData.endereco}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="numero"
              >
                Número:
              </label>
              <input
                id="numero"
                name="numero"
                type="text"
                value={formData.numero}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="bairro"
              >
                Bairro:
              </label>
              <input
                id="bairro"
                name="bairro"
                type="text"
                value={formData.bairro}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="cidade"
              >
                Cidade:
              </label>
              <input
                id="cidade"
                name="cidade"
                type="text"
                value={formData.cidade}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="cep"
              >
                CEP:
              </label>
              <input
                id="cep"
                name="cep"
                type="text"
                value={formData.cep}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="estado"
              >
                Estado:
              </label>
              <input
                id="estado"
                name="estado"
                type="text"
                value={formData.estado}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
          </div>
        </fieldset>

        {/* Documentos */}
        <fieldset className="mb-6 p-4 border border-darkgreen rounded-lg">
          <legend className="text-xl font-semibold text-darkgreen">
            Documentos
          </legend>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="text-darkgreen font-semibold mb-1" htmlFor="rg">
                RG:
              </label>
              <input
                id="rg"
                name="rg"
                type="text"
                value={formData.rg}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="orgao_emissor"
              >
                Órgão Emissor:
              </label>
              <input
                id="orgao_emissor"
                name="orgao_emissor"
                type="text"
                value={formData.orgao_emissor}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="uf_rg"
              >
                UF:
              </label>
              <input
                id="uf_rg"
                name="uf_rg"
                type="text"
                value={formData.uf_rg}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="carteira_trabalho"
              >
                Carteira de Trabalho:
              </label>
              <input
                id="carteira_trabalho"
                name="carteira_trabalho"
                type="text"
                value={formData.carteira_trabalho}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="serie"
              >
                Série:
              </label>
              <input
                id="serie"
                name="serie"
                type="text"
                value={formData.serie}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="pis"
              >
                PIS:
              </label>
              <input
                id="pis"
                name="pis"
                type="text"
                value={formData.pis}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="cpf"
              >
                CPF:
              </label>
              <input
                id="cpf"
                name="cpf"
                type="text"
                value={formData.cpf}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="telefone"
              >
                Telefone:
              </label>
              <input
                id="telefone"
                name="telefone"
                type="text"
                value={formData.telefone}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
          </div>
        </fieldset>

        {/* Informações Pessoais */}
        <fieldset className="mb-6 p-4 border border-darkgreen rounded-lg">
          <legend className="text-xl font-semibold text-darkgreen">
            Informações Pessoais
          </legend>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="data_nascimento"
              >
                Data de Nascimento:
              </label>
              <input
                id="data_nascimento"
                name="data_nascimento"
                type="date"
                value={formData.data_nascimento}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="local_nascimento"
              >
                Local de Nascimento:
              </label>
              <input
                id="local_nascimento"
                name="local_nascimento"
                type="text"
                value={formData.local_nascimento}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="uf_nascimento"
              >
                UF:
              </label>
              <input
                id="uf_nascimento"
                name="uf_nascimento"
                type="text"
                value={formData.uf_nascimento}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="titulo_eleitor"
              >
                Título de Eleitor:
              </label>
              <input
                id="titulo_eleitor"
                name="titulo_eleitor"
                type="text"
                value={formData.titulo_eleitor}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="zona"
              >
                Zona:
              </label>
              <input
                id="zona"
                name="zona"
                type="text"
                value={formData.zona}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="secao"
              >
                Seção:
              </label>
              <input
                id="secao"
                name="secao"
                type="text"
                value={formData.secao}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
          </div>
        </fieldset>

        {/* Filiação */}
        <fieldset className="mb-6 p-4 border border-darkgreen rounded-lg">
          <legend className="text-xl font-semibold text-darkgreen">
            Filiação
          </legend>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="pai"
              >
                Pai:
              </label>
              <input
                id="pai"
                name="pai"
                type="text"
                value={formData.pai}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-darkgreen font-semibold mb-1"
                htmlFor="mae"
              >
                Mãe:
              </label>
              <input
                id="mae"
                name="mae"
                type="text"
                value={formData.mae}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
              />
            </div>
          </div>
        </fieldset>

        {/* Nome do Cônjuge */}
        <fieldset className="mb-6 p-4 border border-darkgreen rounded-lg">
          <legend className="text-xl font-semibold text-darkgreen">
            Nome do Cônjuge (se houver)
          </legend>
          <div className="flex flex-col">
            <input
              id="conjugue"
              name="conjugue"
              type="text"
              value={formData.conjugue}
              onChange={handleChange}
              placeholder="Nome do Cônjuge"
              className="w-full px-3 py-2 border border-darkgreen rounded-md focus:outline-none focus:border-sky"
            />
          </div>
        </fieldset>

        {/* Botões de Ação */}
        <div className="flex justify-start mt-6 space-x-4">
          <button
            type="submit"
            className="bg-sky text-white px-4 py-2 rounded-md hover:bg-lightgreen"
          >
            Cadastrar Funcionário
          </button>
          <button
            type="button"
            className="bg-vermelho text-white px-4 py-2 rounded-md "
            onClick={handleReset}
          >
            Limpar Formulário
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmployeeAdmissionForm;

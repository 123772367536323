import React, { useState } from "react";

const CalculadoraFatorCorrecao = () => {
  const [quantidadeUsada, setQuantidadeUsada] = useState("");
  const [quantidadeReal, setQuantidadeReal] = useState("");
  const [fatorCorrecao, setFatorCorrecao] = useState(null);

  const calcularFatorCorrecao = () => {
    if (quantidadeUsada && quantidadeReal) {
      const usado = parseFloat(quantidadeUsada);
      const real = parseFloat(quantidadeReal);

      if (!isNaN(usado) && !isNaN(real) && real !== 0) {
        const fator = usado / real;
        setFatorCorrecao(fator.toFixed(2));
      } else {
        alert("Por favor, insira valores numéricos válidos.");
      }
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-4xl font-semibold text-center mb-4">
        Calculadora de Fator de Correção
      </h2>
      <p className="mb-4 font-semibold text-xl">
        O fator de correção em ingredientes culinários refere-se à proporção de
        um ingrediente que pode ser efetivamente utilizado após considerar
        partes não comestíveis ou não utilizáveis. Esse fator é utilizado para
        calcular a quantidade real de um ingrediente disponível para uso em uma
        receita. Por exemplo, quando você compra uma maçã, ela inclui a casca, o
        miolo e as sementes, que não são geralmente usados na receita. O fator
        de correção para a maçã seria a proporção que representa a parte
        comestível da fruta em relação ao peso total. Se uma maçã tem um peso
        total de 100g, e a parte comestível pesa 80g, o fator de correção seria
        de 0,8 ou 80%. O fator de correção é uma maneira de calcular a
        quantidade de ingrediente realmente aproveitável em uma receita,
        evitando desperdício e garantindo a precisão nas receitas ao determinar
        a quantidade real de ingredientes necessários. Pode ser aplicado a
        diversos ingredientes, como vegetais, carnes, peixes, e outros produtos
        alimentícios que possuem partes não comestíveis ou que precisam ser
        removidas antes do consumo.
      </p>

      <div className="mb-4">
        <label
          htmlFor="quantidadeUsada"
          className="block font-semibold text-xl mb-2"
        >
          Peso Bruto Do Ingrediente:
        </label>
        <input
          type="number"
          id="quantidadeUsada"
          value={quantidadeUsada}
          onChange={(e) => setQuantidadeUsada(e.target.value)}
          className="w-full px-4 py-2 border rounded-md text-lg"
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="quantidadeReal"
          className="block font-semibold text-xl mb-2"
        >
          Peso Líquido Do Ingrediente:
        </label>
        <input
          type="number"
          id="quantidadeReal"
          value={quantidadeReal}
          onChange={(e) => setQuantidadeReal(e.target.value)}
          className="w-full px-4 py-2 border rounded-md text-lg"
        />
      </div>

      <button
        onClick={calcularFatorCorrecao}
        className="bg-sky text-white font-semibold  text-xl py-2 px-4 rounded-md hover:bg-blue-700"
      >
        Calcular Fator de Correção
      </button>

      {fatorCorrecao && (
        <div className="mt-4">
          <p className="text-xl font-semibold ">
            Fator de Correção: {fatorCorrecao} <br /> <br />
            Agora que você já sabe o fator de correção do ingrediente, volte
            para a página de adicionar ingredientes e insira o resultado. Dessa
            forma, você terá mais precisão nos números do seu restaurante! Ainda
            não utiliza nosso sistema?{" "}
            <a className="underline text-sky" href="/register">
              {" "}
              Assine já e facilite a gestão do seu estabelecimento!{" "}
            </a>
          </p>
        </div>
      )}
      <div style={{ height: "120px" }} />
    </div>
  );
};

export default CalculadoraFatorCorrecao;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const Cardapios = () => {
  const [cardapios, setCardapios] = useState([]);
  const [novoNome, setNovoNome] = useState("");

  // Estados para os itens
  const [itens, setItens] = useState([]);
  const [itemNome, setItemNome] = useState("");
  const [itemDescricao, setItemDescricao] = useState("");
  const [itemPreco, setItemPreco] = useState("");

  const fetchCardapios = async () => {
    try {
      const response = await axios.get(
        "https://api-foodflw-production.up.railway.app/cardapios",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setCardapios(response.data);
    } catch (error) {
      console.error("Erro ao buscar cardápios:", error);
      toast.error("Erro ao buscar cardápios.");
    }
  };

  const adicionarItem = () => {
    if (!itemNome || !itemDescricao || !itemPreco) {
      toast.warning("Preencha todos os campos do item.");
      return;
    }

    const novoItem = {
      nome: itemNome,
      descricao: itemDescricao,
      preco: parseFloat(itemPreco),
    };

    setItens([...itens, novoItem]);
    setItemNome("");
    setItemDescricao("");
    setItemPreco("");
  };

  const removerItem = (index) => {
    const novosItens = itens.filter((_, i) => i !== index);
    setItens(novosItens);
  };

  const adicionarCardapio = async () => {
    if (!novoNome) {
      toast.warning("Preencha o nome do cardápio.");
      return;
    }

    try {
      await axios.post(
        "https://api-foodflw-production.up.railway.app/cardapios",
        { nome: novoNome, itens }, // Enviando o nome e os itens do cardápio
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchCardapios();
      setNovoNome("");
      setItens([]); // Limpar os itens após adicionar o cardápio
      toast.success("Cardápio adicionado com sucesso!");
    } catch (error) {
      console.error("Erro ao adicionar cardápio:", error);
      toast.error("Erro ao adicionar cardápio.");
    }
  };

  useEffect(() => {
    fetchCardapios();
  }, []);

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-3xl font-semibold mb-6 text-center">
        Gerenciamento de Cardápios
      </h2>

      <div className="mb-6">
        <h4 className="text-xl font-semibold mb-4">Adicionar Novo Cardápio</h4>
        <div>
          <input
            type="text"
            placeholder="Nome do Cardápio"
            value={novoNome}
            onChange={(e) => setNovoNome(e.target.value)}
            className="block w-full px-3 py-2 border rounded"
          />
        </div>

        {/* Seção para adicionar itens */}
        <h4 className="text-xl font-semibold mt-4">Itens do Cardápio</h4>
        <div className="grid grid-cols-3 gap-4 mb-4">
          <input
            type="text"
            placeholder="Nome do Item"
            value={itemNome}
            onChange={(e) => setItemNome(e.target.value)}
            className="block w-full px-3 py-2 border rounded"
          />
          <input
            type="text"
            placeholder="Descrição do Item"
            value={itemDescricao}
            onChange={(e) => setItemDescricao(e.target.value)}
            className="block w-full px-3 py-2 border rounded"
          />
          <input
            type="number"
            placeholder="Preço do Item"
            value={itemPreco}
            onChange={(e) => setItemPreco(e.target.value)}
            className="block w-full px-3 py-2 border rounded"
          />
        </div>

        <button
          onClick={adicionarItem}
          className="mb-4 bg-darkgreen text-white px-4 py-2 rounded hover:bg-green-700"
        >
          Adicionar Item
        </button>

        {/* Lista de itens adicionados */}
        <div>
          <h4 className="text-xl font-semibold mb-4">Itens Adicionados:</h4>
          {itens.length > 0 ? (
            itens.map((item, index) => (
              <div
                key={index}
                className="p-2 bg-gray-100 mb-2 flex justify-between items-center"
              >
                <div>
                  <span className="font-semibold">{item.nome}</span> -{" "}
                  {item.descricao} - R$ {item.preco.toFixed(2)}
                </div>
                <button
                  onClick={() => removerItem(index)}
                  className="text-red-600 hover:text-red-800"
                >
                  Remover
                </button>
              </div>
            ))
          ) : (
            <p className="text-gray-600">Nenhum item adicionado.</p>
          )}
        </div>

        <button
          onClick={adicionarCardapio}
          className="mt-4 bg-darkgreen text-white px-4 py-2 rounded hover:bg-green-700"
        >
          Adicionar Cardápio
        </button>
      </div>

      <div>
        <h4 className="text-xl font-semibold mb-4">Cardápios:</h4>
        {cardapios.length > 0 ? (
          cardapios.map((cardapio) => (
            <div
              key={cardapio.id}
              className="p-4 bg-white shadow-md mb-4 rounded"
            >
              <span className="font-semibold text-lg">{cardapio.nome}</span>
              <a
                href={`/cardapio/${cardapio.link_unico}`}
                className="text-blue-600 hover:text-blue-800 ml-4"
              >
                Ver Cardápio
              </a>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-600">
            Nenhum cardápio cadastrado.
          </p>
        )}
      </div>
    </div>
  );
};

export default Cardapios;

import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

function RedefinirSenhaSolicitacao() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        "https://api-foodflw-production.up.railway.app/redefinir-senha",
        { email }
      );
      toast.success("Verifique seu email para redefinir a senha!");

      setMessage("Link de redefinição de senha enviado para seu e-mail");
    } catch (error) {
      setMessage("Erro ao enviar link de redefinição de senha");
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <form onSubmit={handleSubmit} className="space-y-4">
        <h1 className="text-2xl">Redefinição de Senha</h1>
        <input
          type="email"
          placeholder="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-2 border rounded"
        />
        <button
          type="submit"
          className="w-full py-2 bg-darkgreen text-white rounded"
        >
          Enviar
        </button>
      </form>
      {message && <p className="mt-4">{message}</p>}
    </div>
  );
}

export default RedefinirSenhaSolicitacao;

import React, { useState, useEffect } from "react";
import axios from "axios";
import Papa from "papaparse";
import { FaEdit, FaTrash, FaSearch } from "react-icons/fa";

function ImportClientes() {
  // Estados para arquivo, dados CSV e mensagens
  const [file, setFile] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [message, setMessage] = useState("");
  const [clientesData, setClientesData] = useState([]);

  // Estados para especificar os nomes das colunas
  const [colunaNome, setColunaNome] = useState("");
  const [colunaEmail, setColunaEmail] = useState("");
  const [colunaTelefone, setColunaTelefone] = useState("");
  const [colunaEndereco, setColunaEndereco] = useState("");
  const [colunaCidade, setColunaCidade] = useState("");
  const [colunaEstado, setColunaEstado] = useState("");
  const [colunaCep, setColunaCep] = useState("");

  // Estado para controlar a exibição do formulário de cadastro manual
  const [showManualForm, setShowManualForm] = useState(false);

  // Estados para os dados do cliente a ser cadastrado manualmente
  const [manualCliente, setManualCliente] = useState({
    nome: "",
    email: "",
    telefone: "",
    endereco: "",
    cidade: "",
    estado: "",
    cep: "",
  });

  // Estado para a barra de pesquisa
  const [searchQuery, setSearchQuery] = useState("");

  // Lidar com a seleção de arquivo
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    // Analisar o arquivo CSV com Papaparse
    Papa.parse(selectedFile, {
      complete: (result) => {
        console.log("Dados CSV processados:", result.data);
        setCsvData(result.data);
      },
      header: true,
      skipEmptyLines: true,
    });
  };

  // Função para lidar com a importação
  const handleImport = async () => {
    if (!file) {
      alert("Por favor, selecione um arquivo CSV para importar.");
      return;
    }

    console.log("Iniciando importação...");

    // Crie um objeto FormData
    const formData = new FormData();
    formData.append("file", file);
    formData.append("colunaNome", colunaNome);
    formData.append("colunaEmail", colunaEmail);
    formData.append("colunaTelefone", colunaTelefone);
    formData.append("colunaEndereco", colunaEndereco);
    formData.append("colunaCidade", colunaCidade);
    formData.append("colunaEstado", colunaEstado);
    formData.append("colunaCep", colunaCep);

    try {
      console.log("Enviando solicitação à API...");

      // Envie a solicitação POST para a API
      const response = await axios.post(
        "https://api-foodflw-production.up.railway.app/importar-clientes",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Certifique-se de que o token está correto
          },
        }
      );

      console.log("Resposta da API:", response.data);
      setMessage(response.data.message);

      // Atualize os dados dos clientes após a importação
      fetchClientes();
    } catch (error) {
      console.error("Erro ao importar clientes:", error);
      setMessage("Erro ao importar clientes");
    }
  };

  // Função para buscar clientes do banco de dados
  const fetchClientes = async () => {
    try {
      const response = await axios.get(
        "https://api-foodflw-production.up.railway.app/clientes",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Transforme os dados para garantir que a propriedade id esteja correta
      const transformedClientesData = response.data.map((cliente) => ({
        ...cliente,
        id: cliente.id, // Ajuste conforme necessário
      }));

      // Atualize os dados dos clientes
      setClientesData(transformedClientesData);
    } catch (error) {
      console.error("Erro ao buscar clientes:", error);
      setMessage("Erro ao buscar clientes");
    }
  };

  // Carregue os dados dos clientes quando o componente for montado
  useEffect(() => {
    fetchClientes();
  }, []);

  // Função para acessar os dados de uma linha CSV com base nos nomes das colunas especificados pelo usuário
  const getCsvValue = (row, coluna) => {
    return row[coluna] || "";
  };

  // Função para lidar com o cadastro manual de clientes
  const handleManualRegister = async (e) => {
    e.preventDefault();

    try {
      // Enviar solicitação POST para a API com os dados do cliente manual
      const response = await axios.post(
        "https://api-foodflw-production.up.railway.app/cadastrar-cliente",
        manualCliente,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      console.log("Resposta da API:", response.data);
      setMessage(response.data.message);

      // Atualizar os dados dos clientes após o cadastro manual
      fetchClientes();

      // Resetar o formulário e fechar o modal
      setManualCliente({
        nome: "",
        email: "",
        telefone: "",
        endereco: "",
        cidade: "",
        estado: "",
        cep: "",
      });
      setShowManualForm(false);
    } catch (error) {
      console.error("Erro ao cadastrar cliente manualmente:", error);
      setMessage("Erro ao cadastrar cliente manualmente");
    }
  };

  // Função para editar um cliente
  const handleEdit = (cliente) => {
    // Lógica para edição do cliente
    console.log("Editar cliente:", cliente);
  };
  const handleDelete = async (clienteId) => {
    // Verifica se o clienteId é válido
    if (!clienteId || isNaN(clienteId) || clienteId <= 0) {
      console.error(`ID do cliente inválido ou não fornecido: ${clienteId}`);
      setMessage("ID do cliente inválido ou não fornecido.");
      return;
    }

    try {
      // Enviar solicitação DELETE para a API
      await axios.delete(
        `https://api-foodflw-production.up.railway.app/clientes/${clienteId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Atualize os dados dos clientes após a exclusão
      fetchClientes();
    } catch (error) {
      console.error("Erro ao excluir cliente:", error);
      setMessage("Erro ao excluir cliente");
    }
  };

  // Filtra os clientes com base na consulta de pesquisa
  const filteredClientes = clientesData.filter((cliente) =>
    cliente.nome.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Renderização do componente
  return (
    <div className="container mx-auto p-4 bg-gray-100">
      <h1 className="text-3xl font-semibold mb-4">Base de Clientes</h1>

      {/* Barra de pesquisa */}
      <div className="flex mb-4">
        <input
          type="text"
          placeholder="Pesquisar clientes..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-l"
        />
        <button className="bg-sky hover:bg-blue-700 text-white p-2 rounded-r">
          <FaSearch />
        </button>
      </div>

      {/* Importação de CSV */}
      <div className="mb-4">
        <label
          htmlFor="csvFile"
          className="block text-lg font-medium text-gray-700 mb-2"
        >
          Selecione um arquivo CSV:
        </label>
        <input
          type="file"
          id="csvFile"
          accept=".csv"
          onChange={handleFileChange}
          className="block w-full text-sm text-gray-500 cursor-pointer file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-sky file:text-white hover:file:bg-sky"
        />
      </div>

      {/* Campos para especificar os nomes das colunas */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        {/* Campos para definir os nomes das colunas */}
        {[
          {
            label: "Nome da coluna de nome:",
            value: colunaNome,
            setValue: setColunaNome,
          },
          {
            label: "Nome da coluna de email:",
            value: colunaEmail,
            setValue: setColunaEmail,
          },
          {
            label: "Nome da coluna de telefone:",
            value: colunaTelefone,
            setValue: setColunaTelefone,
          },
          {
            label: "Nome da coluna de endereço:",
            value: colunaEndereco,
            setValue: setColunaEndereco,
          },
          {
            label: "Nome da coluna de cidade:",
            value: colunaCidade,
            setValue: setColunaCidade,
          },
          {
            label: "Nome da coluna de estado:",
            value: colunaEstado,
            setValue: setColunaEstado,
          },
          {
            label: "Nome da coluna de CEP:",
            value: colunaCep,
            setValue: setColunaCep,
          },
        ].map(({ label, value, setValue }) => (
          <div key={label}>
            <label className="block text-lg font-medium text-gray-700 mb-2">
              {label}
            </label>
            <input
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="block w-full border border-gray-300 rounded p-2"
              placeholder="Deixe vazio para usar padrão"
            />
          </div>
        ))}
      </div>

      {/* Botões de ações */}
      <div className="mb-4 flex justify-end">
        <button
          onClick={handleImport}
          className="bg-sky hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded mr-4"
        >
          Importar CSV
        </button>
        <button
          onClick={() => setShowManualForm(true)}
          className="bg-sky hover:bg-green-700 text-white font-semibold py-2 px-4 rounded"
        >
          Cadastrar manualmente
        </button>
      </div>

      {/* Mensagem de feedback */}
      {message && (
        <div className="mt-4">
          <p className="text-lg font-semibold">{message}</p>
        </div>
      )}

      {/* Exibir dados dos clientes cadastrados em uma tabela */}
      {filteredClientes.length > 0 && (
        <div className="mt-4">
          <h2 className="text-xl font-bold mb-2">Clientes Cadastrados</h2>
          <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead>
              <tr className="bg-sky text-white">
                <th className="p-2 text-left">Nome</th>
                <th className="p-2 text-left">Email</th>
                <th className="p-2 text-left">Telefone</th>
                <th className="p-2 text-left">Endereço</th>
                <th className="p-2 text-left">Cidade</th>
                <th className="p-2 text-left">Estado</th>
                <th className="p-2 text-left">CEP</th>
                <th className="p-2 text-center">Ações</th>{" "}
                {/* Coluna de ações */}
              </tr>
            </thead>
            <tbody>
              {filteredClientes.map((cliente, index) => (
                <tr key={index} className="border-b border-gray-200">
                  <td className="p-2">{cliente.nome}</td>
                  <td className="p-2">{cliente.email}</td>
                  <td className="p-2">{cliente.telefone}</td>
                  <td className="p-2">{cliente.endereco}</td>
                  <td className="p-2">{cliente.cidade}</td>
                  <td className="p-2">{cliente.estado}</td>
                  <td className="p-2">{cliente.cep}</td>
                  <td className="p-2 text-center">
                    {/* Botões de ação */}
                    <button
                      onClick={() => handleEdit(cliente)}
                      className="text-blue-600 hover:text-blue-800 mx-2"
                      title="Editar"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => {
                        handleDelete(cliente.id);
                      }}
                      className="text-red-600 hover:text-red-800 mx-2"
                      title="Excluir"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Formulário de cadastro manual */}
      {showManualForm && (
        <div className="fixed inset-0 bg-sky bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-md w-1/2">
            <h2 className="text-2xl font-bold mb-4">
              Cadastrar Cliente Manualmente
            </h2>
            <form onSubmit={handleManualRegister}>
              {/* Campos do formulário */}
              {[
                {
                  label: "Nome:",
                  type: "text",
                  value: manualCliente.nome,
                  setValue: (e) =>
                    setManualCliente({
                      ...manualCliente,
                      nome: e.target.value,
                    }),
                },
                {
                  label: "Email:",
                  type: "email",
                  value: manualCliente.email,
                  setValue: (e) =>
                    setManualCliente({
                      ...manualCliente,
                      email: e.target.value,
                    }),
                },
                {
                  label: "Telefone:",
                  type: "tel",
                  value: manualCliente.telefone,
                  setValue: (e) =>
                    setManualCliente({
                      ...manualCliente,
                      telefone: e.target.value,
                    }),
                },
                {
                  label: "Endereço:",
                  type: "text",
                  value: manualCliente.endereco,
                  setValue: (e) =>
                    setManualCliente({
                      ...manualCliente,
                      endereco: e.target.value,
                    }),
                },
                {
                  label: "Cidade:",
                  type: "text",
                  value: manualCliente.cidade,
                  setValue: (e) =>
                    setManualCliente({
                      ...manualCliente,
                      cidade: e.target.value,
                    }),
                },
                {
                  label: "Estado:",
                  type: "text",
                  value: manualCliente.estado,
                  setValue: (e) =>
                    setManualCliente({
                      ...manualCliente,
                      estado: e.target.value,
                    }),
                },
                {
                  label: "CEP:",
                  type: "text",
                  value: manualCliente.cep,
                  setValue: (e) =>
                    setManualCliente({ ...manualCliente, cep: e.target.value }),
                },
              ].map(({ label, type, value, setValue }) => (
                <div key={label} className="mb-4">
                  <label className="block text-lg font-medium text-gray-700 mb-2">
                    {label}
                  </label>
                  <input
                    type={type}
                    value={value}
                    onChange={setValue}
                    className="block w-full border border-gray-300 rounded p-2"
                    required={type !== "text"} // Campos obrigatórios
                  />
                </div>
              ))}

              {/* Botões de ação */}
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-sky hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                >
                  Cadastrar
                </button>
                <button
                  type="button"
                  onClick={() => setShowManualForm(false)}
                  className="bg-vermelho hover:bg-gray-500 text-white font-bold py-2 px-4 rounded"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImportClientes;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FaTrashAlt,
  FaEye,
  FaEyeSlash,
  FaCalculator,
  FaSearch,
} from "react-icons/fa";

const EmployeeList = () => {
  const [funcionarios, setFuncionarios] = useState([]);
  const [detalhesVisiveis, setDetalhesVisiveis] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [motivoDemissao, setMotivoDemissao] = useState("");
  const [funcionarioParaDemitir, setFuncionarioParaDemitir] = useState(null);
  const [termoPesquisa, setTermoPesquisa] = useState("");

  const fetchFuncionarios = async () => {
    const apiUrl = "https://api-foodflw-production.up.railway.app/funcionarios";
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setFuncionarios(data);
      } else {
      }
    } catch (error) {}
  };

  // Função para filtrar os funcionários com base no termo de pesquisa
  const filtrarFuncionarios = () => {
    return funcionarios.filter((funcionario) => {
      // Verifica se o termo de pesquisa está presente nos campos desejados
      const nome = funcionario.funcionario.toLowerCase();
      const empresa = funcionario.empresa.toLowerCase();
      const termo = termoPesquisa.toLowerCase();
      return nome.includes(termo) || empresa.includes(termo);
    });
  };

  const demitirFuncionario = async (id, motivo) => {
    try {
      const apiUrl = `https://api-foodflw-production.up.railway.app/funcionarios/${id}`;
      const token = localStorage.getItem("token");

      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ motivo }),
      });

      if (response.ok) {
        fetchFuncionarios();
        toast.success("Funcionário demitido com sucesso!");
      } else {
      }
    } catch (error) {}
  };

  const abrirModalDemissao = (id) => {
    setFuncionarioParaDemitir(id);
    setIsModalOpen(true);
  };

  const confirmarDemissao = () => {
    demitirFuncionario(funcionarioParaDemitir, motivoDemissao);
    fecharModalDemissao();
  };

  const fecharModalDemissao = () => {
    setIsModalOpen(false);
    setMotivoDemissao("");
  };

  const alternarDetalhes = (id) => {
    setDetalhesVisiveis(detalhesVisiveis === id ? null : id);
  };

  const formatarData = (data) => {
    const dataObj = new Date(data);
    const dia = String(dataObj.getDate()).padStart(2, "0");
    const mes = String(dataObj.getMonth() + 1).padStart(2, "0");
    const ano = dataObj.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  useEffect(() => {
    fetchFuncionarios();
  }, []);

  // Filtrar os funcionários com base no termo de pesquisa
  const funcionariosFiltrados = filtrarFuncionarios();

  return (
    <div className="p-10 container mx-auto">
      <h2 className="text-3xl font-semibold text-center mb-6 text-darkgreen">
        Funcionários Cadastrados
      </h2>

      {/* Campo de pesquisa */}
      <div className="flex mb-4">
        <input
          type="text"
          placeholder="Pesquisar funcionários..."
          value={termoPesquisa}
          onChange={(e) => setTermoPesquisa(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-l"
        />
        <button className="bg-sky hover:bg-blue-700 text-white p-2 rounded-r">
          <FaSearch />
        </button>
      </div>

      {/* Exibição dos funcionários filtrados */}
      {funcionariosFiltrados.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-60 border border-gray-300 rounded-lg p-6 shadow-md">
          <p className="text-darkgreen text-xl font-semibold mb-4">
            Nenhum funcionário encontrado com o termo de pesquisa.
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md-grid-cols-2 lg:grid-cols-3 gap-6">
          {funcionariosFiltrados.map((funcionario) => (
            <div
              key={funcionario.id}
              className="bg-white p-4 shadow rounded-lg"
            >
              <h3 className="text-xl font-semibold text-darkgreen mb-2">
                {funcionario.funcionario}
              </h3>
              <p className="mb-2">
                <strong>Empresa:</strong> {funcionario.empresa}
              </p>
              <p className="mb-2">
                <strong>Data de Admissão:</strong>{" "}
                {formatarData(funcionario.data_admissao)}
              </p>
              <p className="mb-2">
                <strong>Função:</strong> {funcionario.funcao}
              </p>
              <p className="mb-2">
                <strong>Salário:</strong> R${" "}
                {funcionario.salario != null
                  ? `R$ ${funcionario.salario.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                    })}`
                  : "Salário não informado"}
              </p>
              <p className="mb-2">
                <strong>Contato:</strong> {funcionario.telefone}
              </p>

              <div className="flex items-center space-x-2">
                <button
                  onClick={() => alternarDetalhes(funcionario.id)}
                  className="flex items-center space-x-1 text-sky hover:text-sky-600"
                >
                  {detalhesVisiveis === funcionario.id ? (
                    <>
                      <FaEyeSlash />
                      <span>Ver menos</span>
                    </>
                  ) : (
                    <>
                      <FaEye />
                      <span>Ver mais</span>
                    </>
                  )}
                </button>
              </div>

              {detalhesVisiveis === funcionario.id && (
                <div className="mt-4">
                  <p>
                    <strong>Endereço:</strong> {funcionario.endereco}
                  </p>
                  <p>
                    <strong>Bairro:</strong> {funcionario.bairro}
                  </p>
                  <p>
                    <strong>Cidade:</strong> {funcionario.cidade}
                  </p>
                  <p>
                    <strong>CEP:</strong> {funcionario.cep}
                  </p>
                  <p>
                    <strong>Estado:</strong> {funcionario.estado}
                  </p>
                  <p>
                    <strong>Data de Nascimento:</strong>{" "}
                    {formatarData(funcionario.data_nascimento)}
                  </p>
                  <p>
                    <strong>Local de Nascimento:</strong>{" "}
                    {funcionario.local_nascimento}
                  </p>
                  <p>
                    <strong>UF de Nascimento:</strong>{" "}
                    {funcionario.uf_nascimento}
                  </p>
                  <p>
                    <strong>RG:</strong> {funcionario.rg}
                  </p>
                  <p>
                    <strong>Órgão Emissor:</strong> {funcionario.orgao_emissor}
                  </p>
                  <p>
                    <strong>UF RG:</strong> {funcionario.uf_rg}
                  </p>
                  <p>
                    <strong>Carteira de Trabalho:</strong>{" "}
                    {funcionario.carteira_trabalho}
                  </p>
                  <p>
                    <strong>Série:</strong> {funcionario.serie}
                  </p>
                  <p>
                    <strong>PIS:</strong> {funcionario.pis}
                  </p>
                  <p>
                    <strong>CPF:</strong> {funcionario.cpf}
                  </p>
                  <p>
                    <strong>Título de Eleitor:</strong>{" "}
                    {funcionario.titulo_eleitor}
                  </p>
                  <p>
                    <strong>Zona:</strong> {funcionario.zona}
                  </p>
                  <p>
                    <strong>Seção:</strong> {funcionario.secao}
                  </p>
                  <p>
                    <strong>Pai:</strong> {funcionario.pai}
                  </p>
                  <p>
                    <strong>Mãe:</strong> {funcionario.mae}
                  </p>
                  <p>
                    <strong>Cônjuge:</strong> {funcionario.conjugue}
                  </p>
                </div>
              )}

              <div className="flex space-x-2 mt-4">
                <Link
                  to={`/funcionarios/desempenho/${funcionario.funcionario}`}
                  className="flex items-center justify-center w-1/2 py-2 rounded bg-sky text-white hover:bg-lightgreen"
                >
                  <FaCalculator className="mr-2" />
                  Calcular Desempenho
                </Link>
                <button
                  onClick={() => abrirModalDemissao(funcionario.id)}
                  className="flex items-center justify-center w-1/2 py-2 rounded bg-vermelho text-white hover:bg-red-600"
                >
                  <FaTrashAlt className="mr-2" />
                  Demitir
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Modal para confirmar demissão */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-sky bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h3 className="text-2xl font-semibold text-darkgreen mb-4">
              Demitir Funcionário
            </h3>
            <label
              htmlFor="motivo"
              className="block font-semibold mb-2 text-darkgreen"
            >
              Motivo da demissão:
            </label>
            <textarea
              id="motivo"
              value={motivoDemissao}
              onChange={(e) => setMotivoDemissao(e.target.value)}
              className="w-full p-2 border border-darkgreen rounded-lg mb-4"
              rows="4"
              placeholder="Descreva o motivo da demissão"
            ></textarea>
            <div className="flex justify-end space-x-2">
              <button
                onClick={fecharModalDemissao}
                className="bg-darkgreen text-white py-2 px-4 rounded hover:bg-darkgreen/80"
              >
                Cancelar
              </button>
              <button
                onClick={confirmarDemissao}
                className="bg-sky text-white py-2 px-4 rounded hover:bg-sky/80"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}

      <div style={{ height: "200px" }} />
    </div>
  );
};

export default EmployeeList;

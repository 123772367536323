import React from "react";
import { Link } from "react-router-dom";
import { FaUtensils, FaUserPlus, FaCartPlus } from "react-icons/fa";
import {
  MdRestaurantMenu,
  MdInventory2,
  MdReceipt,
  MdMonetizationOn,
  MdBusinessCenter,
  MdPreview,
} from "react-icons/md";

const Sidebar = ({ onMenuItemClick }) => {
  const menuItems = [
    {
      title: "Meus Ingredientes",
      icon: <MdInventory2 />,
      link: "/meus-ingredientes",
    },
    {
      title: "Adicionar Ingrediente",
      icon: <FaCartPlus />,
      link: "/adicionar-ingrediente",
    },
    {
      title: "Adicionar Receita",
      icon: <MdRestaurantMenu />,
      link: "/adicionar-receita",
    },
    {
      title: "Minhas Receitas",
      icon: <MdReceipt />,
      link: "/minhas-receitas",
      action: onMenuItemClick,
    },
    {
      title: "Estoque",
      icon: <MdInventory2 />,
      link: "/estoque",
      action: onMenuItemClick,
    },
    {
      title: "Despesas",
      icon: <MdMonetizationOn />,
      link: "/minhas-despesas",
    },
    {
      title: "Garçom",
      icon: <FaUtensils />,
      link: "/vendas",
      action: onMenuItemClick,
    },
    {
      title: "Cadastrar Funcionários",
      icon: <FaUserPlus />,
      link: "/funcionarios",
    },
    {
      title: "Cardápio - Criar",
      icon: <MdBusinessCenter />,
      link: "/cardapios", // Criar um novo cardápio
    },

    {
      title: "Comandas",
      icon: <MdBusinessCenter />,
      link: `/comandas`, // Ver o cardápio público (adaptar com o link único se necessário)
    },
    {
      title: "Pedidos",
      icon: <MdReceipt />,
      link: "/pedidos", // Ver todos os pedidos
    },
    {
      title: "Gestão Empresarial",
      icon: <MdBusinessCenter />,
      link: "/gestao-empresarial",
    },
    {
      title: "Ver Meus Funcionários",
      icon: <MdPreview />,
      link: "/meus-funcionarios",
    },
    {
      title: "Ver Cardápio Público",
      icon: <MdBusinessCenter />,
      link: `/cardapio/publico`, // Ver o cardápio público (adaptar com o link único se necessário)
    },
  ];

  return (
    <div className="w-full md:w-1/6 bg-cinza text-darkgreen h-screen fixed left-0 p-6 shadow-lg overflow-y-auto">
      <h1 className="text-4xl font-bold mb-10 text-center">Painel</h1>
      <ul className="space-y-4">
        {menuItems.map((item, index) => (
          <li key={index}>
            <Link
              to={item.link}
              className="flex items-center p-2 rounded-lg hover:bg-gray-700 transition duration-300"
              onClick={item.action}
            >
              <span className="mr-3 text-2xl">{item.icon}</span>
              <span className="text-xl">{item.title}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;

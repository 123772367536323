import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";

const HeaderInfo = () => {
  return (
    <div className="mb-8 p-6 bg-cinza rounded shadow-lg">
      <h2 className="text-2xl font-semibold mb-4">
        Entendendo Custos e Despesas
      </h2>
      <p className="mb-4">
        <strong>Custo:</strong> Refere-se a todos os gastos diretamente ligados
        à produção de bens ou serviços. Por exemplo, matéria-prima e mão de obra
        são custos em uma fábrica.
      </p>
      <p className="mb-4">
        <strong>Despesa:</strong> São os gastos necessários para manter a
        operação da empresa, mas que não estão diretamente ligados à produção.
        Exemplos incluem aluguel e marketing.
      </p>
      <p className="mb-4">
        <strong>Custo Fixo:</strong> Não varia com o volume de produção.
        Exemplo: aluguel de um espaço de fábrica.
      </p>
      <p className="mb-4">
        <strong>Custo Variável:</strong> Varia de acordo com o volume de
        produção. Exemplo: custo da matéria-prima.
      </p>
      <p className="mb-4">
        <strong>Despesa Fixa:</strong> Não muda com o volume de vendas ou
        produção. Exemplo: salário do administrativo.
      </p>
      <p>
        <strong>Despesa Variável:</strong> Varia conforme o volume de vendas ou
        produção. Exemplo: comissão de vendas.
      </p>
    </div>
  );
};

const DespesasFixas = () => {
  const [despesasFixas, setDespesasFixas] = useState([]);
  const [novoNome, setNovoNome] = useState("");
  const [novoValor, setNovoValor] = useState("");
  const [novoMes, setNovoMes] = useState(1);
  const [novoAno, setNovoAno] = useState(new Date().getFullYear());
  const [editandoId, setEditandoId] = useState(null);
  const [editandoNome, setEditandoNome] = useState("");
  const [editandoValor, setEditandoValor] = useState("");
  const [editandoMes, setEditandoMes] = useState(1);
  const [editandoAno, setEditandoAno] = useState(new Date().getFullYear());

  // Função para buscar todas as despesas fixas do usuário
  const fetchDespesasFixas = async () => {
    try {
      const response = await axios.get(
        "https://api-foodflw-production.up.railway.app/despesas-fixas",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setDespesasFixas(response.data);
    } catch (error) {
      console.error("Erro ao buscar despesas fixas:", error);
      toast.error("Erro ao buscar despesas fixas.");
    }
  };

  // Função para adicionar uma nova despesa fixa
  const adicionarDespesaFixa = async () => {
    if (!novoNome || !novoValor || !novoMes || !novoAno) {
      toast.warning("Preencha todos os campos obrigatórios.");
      return;
    }

    try {
      await axios.post(
        "https://api-foodflw-production.up.railway.app/despesas-fixas",
        {
          nome: novoNome,
          valor: parseFloat(novoValor),
          mes: parseInt(novoMes, 10),
          ano: novoAno,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchDespesasFixas();
      // Limpar os campos após adicionar a despesa fixa
      setNovoNome("");
      setNovoValor("");
      setNovoMes(1);
      setNovoAno(new Date().getFullYear());
      toast.success("Despesa fixa adicionada com sucesso!");
    } catch (error) {
      console.error("Erro ao adicionar despesa fixa:", error);
      toast.error("Erro ao adicionar despesa fixa.");
    }
  };

  // Função para editar uma despesa fixa
  const editarDespesaFixa = async (id) => {
    if (!editandoNome || !editandoValor || !editandoMes || !editandoAno) {
      toast.warning("Preencha todos os campos obrigatórios.");
      return;
    }

    try {
      await axios.put(
        `https://api-foodflw-production.up.railway.app/despesas-fixas/${id}`,
        {
          nome: editandoNome,
          valor: parseFloat(editandoValor),
          mes: parseInt(editandoMes, 10),
          ano: parseInt(editandoAno, 10),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchDespesasFixas();
      setEditandoId(null);
      setEditandoNome("");
      setEditandoValor("");
      setEditandoMes(1);
      setEditandoAno(new Date().getFullYear());
      toast.success("Despesa fixa editada com sucesso!");
    } catch (error) {
      console.error("Erro ao editar despesa fixa:", error);
      toast.error("Erro ao editar despesa fixa.");
    }
  };

  // Função para excluir uma despesa fixa
  const excluirDespesaFixa = async (id) => {
    try {
      await axios.delete(
        `https://api-foodflw-production.up.railway.app/despesas-fixas/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      fetchDespesasFixas();
      toast.success("Despesa fixa excluída com sucesso!");
    } catch (error) {
      console.error("Erro ao excluir despesa fixa:", error);
      toast.error("Erro ao excluir despesa fixa.");
    }
  };

  // Fetch despesas fixas ao carregar o componente
  useEffect(() => {
    fetchDespesasFixas();
  }, []);

  // Função para obter o nome do mês com base em um número de mês
  const getMonthName = (monthNumber) => {
    const months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
    return months[monthNumber - 1];
  };

  return (
    <div className="container mx-auto p-6">
      <HeaderInfo />
      <h2 className="text-3xl font-semibold mb-6 text-center text-sky">
        Gerenciamento de Despesas
      </h2>

      {/* Formulário para adicionar uma nova despesa fixa */}
      <div className="mb-6">
        <h4 className="text-xl font-semibold mb-4">
          Adicionar Nova Despesa Fixa
        </h4>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div>
            <label htmlFor="novoNome" className="block font-medium mb-2">
              Nome da Despesa
            </label>
            <input
              id="novoNome"
              type="text"
              placeholder="Ex: Aluguel"
              value={novoNome}
              onChange={(e) => setNovoNome(e.target.value)}
              className="block w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:ring-sky"
            />
          </div>
          <div>
            <label htmlFor="novoValor" className="block font-medium mb-2">
              Valor da Despesa
            </label>
            <input
              id="novoValor"
              type="number"
              placeholder="Ex: 1000"
              value={novoValor}
              onChange={(e) => setNovoValor(e.target.value)}
              className="block w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:ring-sky"
            />
          </div>
          <div>
            <label htmlFor="novoMes" className="block font-medium mb-2">
              Mês da Despesa
            </label>
            <select
              id="novoMes"
              value={novoMes}
              onChange={(e) => setNovoMes(parseInt(e.target.value))}
              className="block w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:ring-sky"
            >
              {Array.from({ length: 12 }, (_, index) => (
                <option key={index} value={index + 1}>
                  {getMonthName(index + 1)}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="novoAno" className="block font-medium mb-2">
              Ano da Despesa
            </label>
            <input
              id="novoAno"
              type="number"
              value={novoAno}
              onChange={(e) => setNovoAno(parseInt(e.target.value))}
              className="block w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:ring-sky"
            />
          </div>
        </div>
        <button
          onClick={adicionarDespesaFixa}
          className="mt-4 bg-darkgreen text-white font-semibold px-4 py-2 rounded hover:bg-sky focus:outline-none focus:ring focus:ring-darkgreen"
        >
          Adicionar Despesa Fixa
        </button>
      </div>

      {/* Lista de despesas fixas */}
      <div className="mb-8">
        <h4 className="text-xl font-semibold mb-4">Despesas Fixas:</h4>
        {despesasFixas.length > 0 ? (
          despesasFixas.map((despesa) => (
            <div
              key={despesa.id}
              className="flex justify-between items-center p-4 mb-2 bg-white shadow-md rounded"
            >
              {editandoId === despesa.id ? (
                <div className="flex w-full items-center space-x-4">
                  <input
                    type="text"
                    placeholder="Nome"
                    value={editandoNome}
                    onChange={(e) => setEditandoNome(e.target.value)}
                    className="flex-1 px-3 py-2 border rounded focus:outline-none focus:ring focus:ring-darkgreen"
                  />
                  <input
                    type="number"
                    placeholder="Valor"
                    value={editandoValor}
                    onChange={(e) => setEditandoValor(e.target.value)}
                    className="flex-1 px-3 py-2 border rounded focus:outline-none focus:ring focus:ring-darkgreen"
                  />
                  <select
                    value={editandoMes}
                    onChange={(e) => setEditandoMes(parseInt(e.target.value))}
                    className="flex-1 px-3 py-2 border rounded focus:outline-none focus:ring focus:ring-darkgreen"
                  >
                    {Array.from({ length: 12 }, (_, index) => (
                      <option key={index} value={index + 1}>
                        {getMonthName(index + 1)}
                      </option>
                    ))}
                  </select>
                  <input
                    id="editandoAno"
                    type="number"
                    value={editandoAno}
                    onChange={(e) => setEditandoAno(parseInt(e.target.value))}
                    className="flex-1 px-3 py-2 border rounded focus:outline-none focus:ring focus:ring-darkgreen"
                  />
                  <button
                    onClick={() => editarDespesaFixa(despesa.id)}
                    className="bg-darkgreen text-white font-semibold px-4 py-2 rounded hover:bg-sky focus:outline-none focus:ring focus:ring-darkgreen"
                  >
                    Salvar
                  </button>
                </div>
              ) : (
                <div className="flex justify-between w-full">
                  <div className="flex flex-col">
                    <span className="font-semibold text-lg">
                      {despesa.nome}
                    </span>
                    <span className="text-gray-600 text-sm">
                      R$ {parseFloat(despesa.valor).toFixed(2)} - Mês:{" "}
                      {getMonthName(despesa.mes)} - Ano: {despesa.ano}
                    </span>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => {
                        setEditandoId(despesa.id);
                        setEditandoNome(despesa.nome);
                        setEditandoValor(despesa.valor);
                        setEditandoMes(despesa.mes);
                        setEditandoAno(despesa.ano);
                      }}
                      className="text-sky hover:text-darkgreen focus:outline-none focus:ring focus:ring-sky p-2 rounded"
                    >
                      <FaEdit size={18} />
                    </button>
                    <button
                      onClick={() => excluirDespesaFixa(despesa.id)}
                      className="text-vermelho hover:text-red-600 focus:outline-none focus:ring focus:ring-vermelho p-2 rounded"
                    >
                      <FaTrash size={18} />
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="text-center font-medium text-gray-600">
            Nenhuma despesa fixa cadastrada.
          </p>
        )}
      </div>
    </div>
  );
};

export default DespesasFixas;

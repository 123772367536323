import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const ItensPedidos = ({ pedidoId }) => {
  const [itens, setItens] = useState([]);

  const fetchItens = async () => {
    try {
      const response = await axios.get(
        `https://api-foodflw-production.up.railway.app/pedidos/${pedidoId}/itens`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setItens(response.data);
    } catch (error) {
      console.error("Erro ao buscar itens do pedido:", error);
      toast.error("Erro ao buscar itens do pedido.");
    }
  };

  useEffect(() => {
    fetchItens();
  }, [pedidoId]);

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-3xl font-semibold mb-6 text-center">
        Itens do Pedido #{pedidoId}
      </h2>

      <div>
        {itens.length > 0 ? (
          itens.map((item) => (
            <div key={item.id} className="p-4 bg-white shadow-md mb-4 rounded">
              <span className="font-semibold text-lg">{item.nome}</span>
              <p>Quantidade: {item.quantidade}</p>
              <p className="text-gray-600">
                R$ {parseFloat(item.preco).toFixed(2)}
              </p>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-600">Nenhum item neste pedido.</p>
        )}
      </div>
    </div>
  );
};

export default ItensPedidos;

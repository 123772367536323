import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

function RedefinirSenha() {
  const { token } = useParams();
  const [novaSenha, setNovaSenha] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        `https://api-foodflw-production.up.railway.app/redefinir-senha/${token}`,
        {
          nova_senha: novaSenha,
        }
      );
      setMessage("Senha redefinida com sucesso");
    } catch (error) {
      setMessage("Erro ao redefinir senha");
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <form onSubmit={handleSubmit} className="space-y-4">
        <h1 className="text-2xl">Definir Nova Senha</h1>
        <input
          type="password"
          placeholder="Nova Senha"
          value={novaSenha}
          onChange={(e) => setNovaSenha(e.target.value)}
          className="w-full p-2 border rounded"
        />
        <button
          type="submit"
          className="w-full py-2 bg-darkgreen text-white rounded"
        >
          Definir
        </button>
      </form>
      {message && <p className="mt-4">{message}</p>}
    </div>
  );
}

export default RedefinirSenha;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";

const PratosMaisVendidos = () => {
  const [dadosGrafico, setDadosGrafico] = useState(null);
  const [resumo, setResumo] = useState(null);

  useEffect(() => {
    const buscarPratosMaisVendidos = async () => {
      try {
        const apiUrl =
          "https://api-foodflw-production.up.railway.app/pratos-mais-vendidos";
        const token = localStorage.getItem("token");

        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          const pratosVendidos = response.data;

          // Processar dados para o gráfico
          const labels = pratosVendidos.map((prato) => prato.prato);
          const data = pratosVendidos.map((prato) => prato.quantidade);

          // Configurar os dados para o gráfico
          const dadosConfig = {
            labels,
            datasets: [
              {
                label: "Quantidade Vendida",
                data,
                backgroundColor: [
                  "rgba(255, 159, 64, 0.6)", // Laranja
                  "rgba(54, 162, 235, 0.6)", // Azul
                ],
                borderColor: [
                  "rgba(255, 159, 64, 1)", // Laranja
                  "rgba(54, 162, 235, 1)", // Azul
                ],
                borderWidth: 1,
                hoverBackgroundColor: [
                  "rgba(255, 159, 64, 1)", // Laranja
                  "rgba(54, 162, 235, 1)", // Azul
                ],
                hoverBorderColor: [
                  "rgba(255, 159, 64, 1)", // Laranja
                  "rgba(54, 162, 235, 1)", // Azul
                ],
                hoverBorderWidth: 2,
              },
            ],
          };

          // Configurar opções para o gráfico
          const options = {
            plugins: {
              title: {
                display: true,
                text: "Pratos Mais Vendidos",
                font: {
                  size: 18,
                  weight: "bold",
                },
              },
              tooltip: {
                callbacks: {
                  label: (context) => {
                    const prato = labels[context.dataIndex];
                    const quantidade = context.raw;

                    return `${prato}: ${quantidade} vendas`;
                  },
                },
              },
              legend: {
                display: true,
                position: "right",
                labels: {
                  font: {
                    size: 14,
                    weight: "bold",
                  },
                },
              },
            },
          };

          // Calcular resumo
          const pratoMaisVendido = pratosVendidos.reduce((a, b) =>
            a.quantidade > b.quantidade ? a : b
          );
          const receitaTotal = pratosVendidos.reduce(
            (total, prato) => total + prato.receita,
            0
          );

          setResumo({
            pratoMaisVendido: pratoMaisVendido.prato,
            quantidadeMaisVendida: pratoMaisVendido.quantidade,
            receitaTotal: receitaTotal.toFixed(2),
          });

          setDadosGrafico({ data: dadosConfig, options });
        } else {
          console.error(
            "Erro ao obter pratos mais vendidos:",
            response.statusText
          );
        }
      } catch (error) {
        console.error("Erro ao obter pratos mais vendidos:", error);
      }
    };

    buscarPratosMaisVendidos();
  }, []);

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-6">Pratos Mais Vendidos</h1>

      {resumo && (
        <div className="mb-4 p-4 bg-gray-100 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold">Resumo:</h2>
          <p>
            <strong>Prato Mais Vendido:</strong> {resumo.pratoMaisVendido}
          </p>
          <p>
            <strong>Quantidade Vendida:</strong> {resumo.quantidadeMaisVendida}
          </p>
        </div>
      )}

      {dadosGrafico ? (
        <div className="w-full md:w-2/3 mx-auto">
          <Pie data={dadosGrafico.data} options={dadosGrafico.options} />
        </div>
      ) : (
        <p>Carregando dados...</p>
      )}
    </div>
  );
};

export default PratosMaisVendidos;

import React from "react";
import { FaEdit, FaSave, FaSearch } from "react-icons/fa";

const ListaIngredientes = ({
  ingredientes,
  termoPesquisa,
  setTermoPesquisa,
  handleEditarPreco,
  editandoPreco,
  novoPreco,
  setNovoPreco,
  handleSalvarPreco,
}) => {
  const filtrarIngredientes = () => {
    return ingredientes.filter((ingrediente) =>
      ingrediente.nome.toLowerCase().includes(termoPesquisa.toLowerCase())
    );
  };

  const ingredientesFiltrados = filtrarIngredientes();

  return (
    <div className="p-6 rounded-lg shadow-md bg-white">
      <h2 className="text-3xl font-semibold text-darkgreen mb-6 text-center">
        Lista de Ingredientes
      </h2>

      {/* Barra de pesquisa */}
      <div className="flex mb-4">
        <input
          type="text"
          placeholder="Pesquisar ingredientes..."
          value={termoPesquisa}
          onChange={(e) => setTermoPesquisa(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-l focus:outline-none"
        />
        <button className="bg-sky hover:bg-blue-700 text-white p-2 rounded-r">
          <FaSearch />
        </button>
      </div>

      {/* Verificar se há ingredientes filtrados */}
      {ingredientesFiltrados.length === 0 ? (
        <div className="flex items-center justify-center h-60 border border-gray-300 rounded-lg p-6 shadow-md">
          <p className="text-darkgreen text-xl font-semibold text-center">
            Nenhum ingrediente encontrado com o termo de pesquisa.
          </p>
        </div>
      ) : (
        <ul className="grid mt-6 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {ingredientesFiltrados.map((ingrediente) => (
            <li
              key={ingrediente.id}
              className="bg-white shadow-md border border-darkgreen rounded-lg p-4 flex items-center justify-between"
            >
              {editandoPreco === ingrediente.id ? (
                <div className="flex items-center w-full">
                  <p className="text-xl font-semibold mr-2">
                    {ingrediente.nome}
                  </p>
                  <input
                    type="number"
                    value={novoPreco}
                    onChange={(e) => setNovoPreco(e.target.value)}
                    className="w-24 px-2 py-1 text-xl border border-gray-300 rounded-md mr-4"
                    aria-label="Novo preço"
                  />

                  <button
                    onClick={handleSalvarPreco}
                    className="bg-darkgreen text-white font-semibold text-lg px-3 py-1 rounded-md flex items-center hover:bg-ultradarkgreen"
                  >
                    <FaSave className="mr-2" />
                    Salvar
                  </button>
                </div>
              ) : (
                <div className="flex items-center w-full">
                  <span className="mr-4 text-xl font-semibold">
                    {ingrediente.nome} - R$ {ingrediente.valor}
                  </span>
                  <button
                    onClick={() =>
                      handleEditarPreco(ingrediente.id, ingrediente.valor)
                    }
                    className="bg-darkgreen text-white px-3 py-1 font-semibold text-lg rounded-md flex items-center hover:bg-ultradarkgreen"
                    aria-label="Editar preço"
                  >
                    <FaEdit className="mr-2" />
                    Editar
                  </button>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ListaIngredientes;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const FoodSafetyIndicators = () => {
  // Estado para armazenar os indicadores de segurança alimentar
  const [indicators, setIndicators] = useState([]);
  // Estado para armazenar a conformidade com normas sanitárias
  const [complianceStatus, setComplianceStatus] = useState(null);
  // Estado para armazenar os dados do novo indicador a ser adicionado
  const [newIndicator, setNewIndicator] = useState({
    name: "",
    type: "",
    date: "",
    details: "",
    is_compliant: false,
  });
  // Estado para rastrear o indicador em edição
  const [editingIndicator, setEditingIndicator] = useState(null);
  // Estado para armazenar os dados do indicador em edição
  const [editingData, setEditingData] = useState({});

  // Função para buscar os indicadores de segurança alimentar
  const fetchIndicators = async () => {
    try {
      const apiUrl =
        "https://api-foodflw-production.up.railway.app/indicators/food-safety";
      const token = localStorage.getItem("token");

      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        // Atualizar o estado com os indicadores recebidos
        setIndicators(response.data);
        // Verificar a conformidade com as normas sanitárias
        verifyCompliance(response.data);
      } else {
        console.error("Erro ao obter indicadores:", response.statusText);
      }
    } catch (error) {
      console.error("Erro ao obter indicadores:", error);
    }
  };

  // Função para adicionar um novo indicador de segurança alimentar
  const addIndicator = async () => {
    try {
      const apiUrl =
        "https://api-foodflw-production.up.railway.app/indicators/food-safety";
      const token = localStorage.getItem("token");

      const response = await axios.post(apiUrl, newIndicator, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 201) {
        // Atualizar a lista de indicadores após adicionar um novo
        fetchIndicators();
        // Limpar os campos do novo indicador
        setNewIndicator({
          name: "",
          type: "",
          date: "",
          details: "",
          is_compliant: false,
        });
        toast.success("Indicador adicionado com sucesso!");
      }
    } catch (error) {
      toast.error("Erro ao adicionar indicador!");
    }
  };

  // Função para lidar com as mudanças nos campos de entrada do novo indicador
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setNewIndicator((prevIndicator) => ({
      ...prevIndicator,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Função para verificar a conformidade com as normas sanitárias
  const verifyCompliance = (data) => {
    // Verifica se há indicadores não conformes
    const nonCompliant = data.filter(
      (indicator) => indicator.is_compliant === false
    );

    if (nonCompliant.length > 0) {
      setComplianceStatus("Não conforme");
    } else {
      setComplianceStatus("Conforme");
    }
  };

  // Função para iniciar a edição de um indicador
  const handleEdit = (indicator) => {
    setEditingIndicator(indicator.id);
    setEditingData({ ...indicator });
  };

  // Função para salvar as alterações de um indicador em edição
  const saveEdit = async () => {
    try {
      const apiUrl = `https://api-foodflw-production.up.railway.app/indicators/food-safety/${editingIndicator}`;
      const token = localStorage.getItem("token");

      const response = await axios.put(apiUrl, editingData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        // Atualizar a lista de indicadores após salvar a edição
        fetchIndicators();
        // Limpar o estado de edição
        setEditingIndicator(null);
        setEditingData({});
        toast.success("Indicador editado com sucesso!");
      } else {
        toast.error("Erro ao editar indicador!");
      }
    } catch (error) {
      toast.error("Erro ao editar indicador!");
    }
  };

  // Função para cancelar a edição de um indicador
  const cancelEdit = () => {
    setEditingIndicator(null);
    setEditingData({});
  };

  // Função para excluir um indicador de segurança alimentar
  const deleteIndicator = async (id) => {
    try {
      const apiUrl = `https://api-foodflw-production.up.railway.app/indicators/food-safety/${id}`;
      const token = localStorage.getItem("token");

      const response = await axios.delete(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        // Atualizar a lista de indicadores após excluir um
        fetchIndicators();
        toast.success("Indicador excluído com sucesso!");
      } else {
        toast.error("Erro ao excluir indicador!");
      }
    } catch (error) {
      toast.error("Erro ao excluir indicador");
    }
  };

  useEffect(() => {
    // Buscar os indicadores ao montar o componente
    fetchIndicators();
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">
        Indicadores de Segurança Alimentar
      </h2>
      <p className="mb-4">
        Status de conformidade com normas sanitárias:{" "}
        <span
          className={
            complianceStatus === "Conforme" ? "text-green-600" : "text-red-600"
          }
        >
          {complianceStatus}
        </span>
      </p>

      {/* Formulário para adicionar um novo indicador */}
      <div className="mb-4">
        <h3 className="text-xl font-bold">Adicionar Novo Indicador</h3>
        <div className="mb-2">
          <label htmlFor="name" className="block font-semibold">
            Nome:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={newIndicator.name}
            onChange={handleInputChange}
            className="w-full border p-2 rounded-md"
          />
        </div>
        <div className="mb-2">
          <label htmlFor="type" className="block font-semibold">
            Tipo:
          </label>
          <input
            type="text"
            id="type"
            name="type"
            value={newIndicator.type}
            onChange={handleInputChange}
            className="w-full border p-2 rounded-md"
          />
        </div>
        <div className="mb-2">
          <label htmlFor="date" className="block font-semibold">
            Data:
          </label>
          <input
            type="date"
            id="date"
            name="date"
            value={newIndicator.date}
            onChange={handleInputChange}
            className="w-full border p-2 rounded-md"
          />
        </div>
        <div className="mb-2">
          <label htmlFor="details" className="block font-semibold">
            Detalhes:
          </label>
          <textarea
            id="details"
            name="details"
            value={newIndicator.details}
            onChange={handleInputChange}
            className="w-full border p-2 rounded-md"
          ></textarea>
        </div>
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            id="is_compliant"
            name="is_compliant"
            checked={newIndicator.is_compliant}
            onChange={handleInputChange}
            className="mr-2"
          />
          <label htmlFor="is_compliant" className="block font-semibold">
            Ao marcar a checkbox, você confirma que este indicador está em
            conformidade.
          </label>
        </div>
        <button
          onClick={addIndicator}
          className="bg-darkgreen text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Adicionar Indicador
        </button>
      </div>

      {/* Exibição dos indicadores */}
      <div className="grid gap-4 md:grid-cols-2 lg-grid-cols-3">
        {indicators.map((indicator, index) => (
          <div key={index} className="bg-white p-4 rounded-lg shadow-md">
            {/* Verifica se o indicador está sendo editado */}
            {editingIndicator === indicator.id ? (
              // Exibição do formulário de edição
              <div>
                <input
                  type="text"
                  name="name"
                  value={editingData.name}
                  onChange={(e) =>
                    setEditingData({ ...editingData, name: e.target.value })
                  }
                  className="w-full border p-2 mb-2 rounded-md"
                />
                <input
                  type="text"
                  name="type"
                  value={editingData.type}
                  onChange={(e) =>
                    setEditingData({ ...editingData, type: e.target.value })
                  }
                  className="w-full border p-2 mb-2 rounded-md"
                />
                <input
                  type="date"
                  name="date"
                  value={editingData.date}
                  onChange={(e) =>
                    setEditingData({ ...editingData, date: e.target.value })
                  }
                  className="w-full border p-2 mb-2 rounded-md"
                />
                <textarea
                  name="details"
                  value={editingData.details}
                  onChange={(e) =>
                    setEditingData({ ...editingData, details: e.target.value })
                  }
                  className="w-full border p-2 mb-2 rounded-md"
                />
                <div className="flex items-center mb-4">
                  <input
                    type="checkbox"
                    name="is_compliant"
                    checked={editingData.is_compliant}
                    onChange={(e) =>
                      setEditingData({
                        ...editingData,
                        is_compliant: e.target.checked,
                      })
                    }
                    className="mr-2"
                  />
                  <label htmlFor="is_compliant" className="block font-semibold">
                    Ao marcar a checkbox, você confirmar que este indicador está
                    em conformidade
                  </label>
                </div>
                <div className="flex justify-between">
                  <button
                    onClick={saveEdit}
                    className="bg-darkgreen text-white px-4 py-2 rounded hover:bg-green-600"
                  >
                    Salvar
                  </button>
                  <button
                    onClick={cancelEdit}
                    className="bg-vermelho text-white px-4 py-2 rounded hover:bg-gray-500"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            ) : (
              // Exibição normal do card do indicador
              <div>
                <h3 className="text-lg font-semibold">{indicator.name}</h3>
                <p>
                  <strong>Tipo:</strong> {indicator.type}
                </p>
                <p>
                  <strong>Data:</strong>{" "}
                  {new Date(indicator.date).toLocaleDateString("pt-BR")}
                </p>
                <p>
                  <strong>Detalhes:</strong> {indicator.details}
                </p>
                <p>
                  <strong>Conformidade:</strong>{" "}
                  {indicator.is_compliant ? (
                    <span className="text-darkgreen">Sim</span>
                  ) : (
                    <span className="text-vermelho">Não</span>
                  )}
                </p>
                {/* Botões de Editar e Excluir */}
                <div className="mt-2 flex justify-between">
                  <button
                    onClick={() => handleEdit(indicator)}
                    className="bg-darkgreen text-white px-4 py-2 rounded hover:bg-blue-600"
                  >
                    Editar
                  </button>
                  <button
                    onClick={() => deleteIndicator(indicator.id)}
                    className="bg-vermelho text-white px-4 py-2 rounded hover:bg-red-600"
                  >
                    Excluir
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FoodSafetyIndicators;
